import React, { useEffect, useState } from "react";
import "./balanceDetails.css";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
    getUserBalanceStats,
    getUserDetails,
} from "../../Redux/Actions/userActions";
import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";
import useCopy from "use-copy";
import { clientLink } from "../../App";

import { toast } from "react-toastify";

const BalanceDetails = observer((props) => {
    const dispatch = useDispatch();
    const { userDetails, setChartExchangeWallet, toastId } = props;
    const { variables,setTransferOpened } = mobxStore;
    const { user, loading } = userDetails;
    const [chartExchange, setChartExchange] = useState();
    const userBalance = useSelector((state) => state.userBalance);
    const { balance, loading: balanceLoading } = userBalance;
    const [userReferralCode, setUserReferralCode] = useState("");
    const [userRefCopied, copyRef, setUserCopy] = useCopy(
        getUserRefferalCodeLink(userReferralCode)
    );
    useEffect(() => {
        if (typeof balanceLoading !== "undefined" && !balanceLoading) {
            const { balanceInfo } = balance;
            const balanceChart = balanceLine(
                "exchange-balance-3",
                balanceInfo.length > 0
                    ? balanceInfo.map((item) => item.balance)
                    : [0],
                "190px",
                85,
                "#E323FF",
                ["1", "2", "3", "4", "5", "6", "7", "8"],
                "#advance-card__card-line-3"
            );
            setChartExchange(balanceChart);
            setChartExchangeWallet(balanceChart);
        }
    }, [userBalance]);

    useEffect(() => {
        dispatch(getUserBalanceStats());
    }, [dispatch]);

    useEffect(()=>{
        if(!loading&&user){
            setUserReferralCode(user.referralCode);
        }
    },[userDetails]);

    const refCopyHandler = () => {
        
        copyRef();
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(
                "Successfully copied",
               
            );
        }
    };
    

    
    return (
        <div
            className={`advance-card-right ${
                variables.depositModalOpened ? "blur-modal" : ""
            }`}
            style={{ position: "relative" }}
        >
            <div className="advance-card__card">
                <div className="advance-card__card-content">
                    <div className="advance-card__card-desc">
                        <p className="advance-card__card-desc-title">
                            Referral Balance
                        </p>
                        <div className="advance-card__card-desc-value">
                            <p className="advance-card__card-desc-value-crypto">
                                {!loading && typeof loading !== "undefined"
                                    ? user.balance.refferalIncome.toFixed(2)
                                    : "0.00"}
                                USD
                            </p>
                        </div>
                    </div>
                    <div className="personal-info__name-form personal-info__name-form--date">
                        <div
                            className="d-block mini-form-sm__box personal-info__name-form-input aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={50}
                            onClick={refCopyHandler}
                            style={{ cursor: "pointer" }}
                        >
                            <input
                                type="text"
                                id="datepicker-1"
                                className="form-control form-control-sm mini-form__input"
                                placeholder={clientLink}
                                autoComplete="off"
                                disabled
                                style={{
                                    padding: "1.25rem 3.5rem 1.25rem 1.5rem",
                                    cursor: "pointer",
                                }}
                                value={getUserRefferalCodeLink(
                                    userReferralCode
                                )}
                                onClick={refCopyHandler}
                            />
                            <img
                                className="personal-info__name-form-input-icon"
                                src="/assets/media/images/icons/copy.svg"
                                alt=""
                                style={{
                                    position: "absolute",
                                    transform: "translateY(-50%)",
                                    right: "1.75rem",
                                    top: "50%",
                                }}
                                width={20}
                            />
                        </div>
                        <button
                            className="btn btn-secondary advance-card__button btn-sm button-transfer"
                          
                            onClick={()=>setTransferOpened(true)}
                        >
                            Transfer
                        </button>
                    </div>
                    {/* <div className="advance-card__card-data">
                        <div className="advance-card__card-chart-wrapper">
                            <div
                                id="advance-card__card-line-3"
                                className="advance-card__card-chart-render"
                                style={{
                                    minHeight: "100px",
                                }}
                            >
                                {chartExchange ? (
                                    <Chart
                                        options={chartExchange.options}
                                        series={chartExchange.series}
                                        type="area"
                                    />
                                ) : null}
                            </div>
                        </div>
                        
                    </div> */}
                </div>
                {/* <div className="advance-card__card-progress">
                    <div
                        className="advance-card__card-progress-bar progress-bar--purple"
                        style={{
                            width: getWidthBalance(
                                !loading && typeof loading !== "undefined"
                                    ? user.balance
                                    : "0%"
                            ),
                        }}
                    />
                </div> */}
            </div>
            <div className="advance-card__card">
                <div className="advance-card__card-content">
                    <div className="advance-card__card-desc">
                        <p className="advance-card__card-desc-title">
                            Referral level
                        </p>
                    </div>
                    <div className="advance-card__card-data">
                        <div className="advance-card__card-chart-wrapper">
                            <div
                                id="advance-card__card-line-4"
                                className="advance-card__card-chart-render"
                                style={{
                                    minHeight: "100px",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="advance-card__card-progress">
                    <div
                        className="advance-card__card-progress-bar progress-bar--green"
                        style={{
                            width:
                                !balanceLoading &&
                                typeof balanceLoading !== "undefined"
                                    ? getUserRefBalanceWidth(
                                          balance.referral.invited.to,
                                          balance.usersMadeDeposit
                                      )
                                    : "0%",
                        }}
                    />

                    {/* <div className="d-flex justify-content-between">
                        <span style={{ fontSize: "1.25rem" }}>
                            {!balanceLoading &&
                            typeof balanceLoading !== "undefined"
                                ? balance.referral.invited.from
                                : null}
                        </span>
                        <span style={{ fontSize: "1.25rem" }}>
                            {!balanceLoading &&
                            typeof balanceLoading !== "undefined"
                                ? balance.referral.invited.to
                                : null}
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    );
});

export default BalanceDetails;

function balanceLine(name, data, width, height, color, categories) {
    // check if this page have this class
    var options = {
        series: [
            {
                name: name,
                data: data,
            },
        ],
        options: {
            chart: {
                width: width,
                height: height,
                type: "area",
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
                stacked: false,
            },
            dataLabels: {
                enabled: false,
            },
            colors: [color],
            stroke: {
                width: 2,
                curve: "smooth",
            },
            fill: {
                type: "gradient",
                colors: [color],
                gradient: {
                    type: "vertical",
                    shadeIntensity: 1,
                    opacityFrom: 0.6,
                    opacityTo: 0,
                    stops: [0, 80, 100],
                },
            },
            grid: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            xaxis: {
                categories: categories,
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { show: false },
            },
            yaxis: {
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { show: false },
            },
            responsive: [
                {
                    breakpoint: 768,
                    options: {
                        chart: {
                            width: "120px",
                            height: 65,
                        },
                    },
                },
            ],
        },
    };

    return options;
}

function getWidthBalance(balance) {
    const userBalance = balance.income - balance.expenses;
    const userBalanceFromRef = balance.refferalIncome;

    if (userBalance > 0 && userBalanceFromRef > 0) {
        return `${(userBalance / (userBalance + userBalanceFromRef)) * 100}%`;
    } else if (userBalance > 0 && userBalanceFromRef === 0) {
        return "100%";
    }
    return "0%";
}

function getUserRefBalanceWidth(to, made) {
    return (made / to) * 100 + "%";
}

function getUserRefferalCodeLink(referralCode) {
    return clientLink + "/sign-up?referral=" + referralCode;
}
