import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mobxStore from "../mobx/mobxStore";
import OutsideAlerter from "../helpers/outsideAlerter";
import {
    createWithdraw,
    getTransaction,
} from "../Redux/Actions/transactionsActions";


import { CREATE_WITHDRAW_RESET, GET_TRANSACTION_RESET } from "../Redux/Constans/transactionsConstans";
import { getReferralStatus, getUserTariff } from "../Redux/Actions/userActions";
import { useSearchParams } from "react-router-dom";
import WithdrawDefault from "./withdraw/default";
import WithdrawRequested from "./withdraw/requested";
import { Toastobjects } from "../App";
const WithdrawModal = observer((props) => {
    const { toastId, toast } = props;
    const dispatch = useDispatch();
    const { variables, setWithdrawModalOpened } = mobxStore;
    const [searchParams, setSearchParams] = useSearchParams();
    const withdrawId = searchParams.get("withdraw_id");
    const [wallet, setWallet] = useState("");
    const [amount, setAmount] = useState(10);
    const userWithdraw = useSelector((state) => state.userWithdraw);
    const { loading, success, error } = userWithdraw;
    const userTariff = useSelector((state) => state.userTariff);
    const { loading: loadingTariff, tariff } = userTariff;
    const userTransaction = useSelector((state) => state.userTransaction);

    const {
        loading: loadingTransaction,
        transaction,
        error: errorGetTransaction,
    } = userTransaction;
 
    const withdrawHandler = () => {
        dispatch(createWithdraw({ amount, wallet }));
    };
    useEffect(() => {
        dispatch(getUserTariff());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && error) {

            if (!toast.isActive(toastId.current)) {
                console.log("toast error");
                toastId.current = toast.error(error,Toastobjects);
            }
        } else if (!loading && success) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Withdrawal request sent successfully",Toastobjects
                );
            }
        }
    }, [userWithdraw]);
  
    useEffect(() => {
     
        if (typeof withdrawId === "string") {
          
            dispatch(getTransaction(withdrawId));
            setWithdrawModalOpened(true);
        }
    }, [dispatch, withdrawId]);
    useEffect(()=>{
        if(!loadingTransaction&&Object.keys(transaction).length !== 0 ){

            setAmount(transaction.amount);
            setWallet(transaction.withdrawWallet);
        }
    },[dispatch,userTransaction]);
    const onOutsideClickHandler = () => {
        setWithdrawModalOpened(false);
        searchParams.delete("withdraw_id");
        setSearchParams(searchParams);
        dispatch({ type: CREATE_WITHDRAW_RESET });
        dispatch({type:GET_TRANSACTION_RESET});
    };

    if (!variables.withdrawModalOpened) {
        return <></>;
    }

    if (success||Object.keys(transaction).length !== 0 ) {
        return (
            <WithdrawRequested
                onOutsideClickHandler={onOutsideClickHandler}
                amount={amount}
                wallet={wallet}
                loadingTariff={loadingTariff}
                tariff={tariff}
                getReceiveAmount={getReceiveAmount}
            />
        );
    }
   
    return (
        <WithdrawDefault
            success={success}
            onOutsideClickHandler={onOutsideClickHandler}
            amount={amount}
            wallet={wallet}
            setWallet={setWallet}
            loadingTariff={loadingTariff}
            setAmount={setAmount}
            tariff={tariff}
            getReceiveAmount={getReceiveAmount}
            withdrawHandler={withdrawHandler}
        />
    );
});

export default WithdrawModal;

function getReceiveAmount(amount, tariff) {
    const { commission } = tariff;
    return amount - (amount * commission) / 100;
}
