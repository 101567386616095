import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserWalletActions } from "../../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import mobxStore from "../../mobx/mobxStore";
import { uniqid } from "../../helpers/uniqid";
const WalletActions = () => {
        const {setDepositModalOpened}=mobxStore;
        const dispatch=useDispatch();
        const userWalletActions=useSelector(state=>state.userWalletActions);
        const {transactions,loading,error}=userWalletActions;

        useEffect(()=>{
                dispatch(getUserWalletActions());
        },[dispatch]);

      console.log(userWalletActions);
    return (
        <div
            className="table-wrapper table--type-3 aos-init aos-animate"
            data-aos="fade-up"
        >
            <div className="table__title">
                <h6 className="table__title-text">События кошельков</h6>
            </div>
            <div className="tab-content">
                <div
                    className="tab-pane fade show active"
                    id="pills-market-trades-open-order"
                    role="tabpanel"
                    aria-labelledby="pills-market-trades-open-order-tab"
                >
                    <div
                        className="table-container"
                        style={{ maxHeight: "600px" }}
                    >
                        <table className="table table-content">
                            <thead>
                                <tr>
                                    <th className="fb-regular table__head ">
                                        Тип
                                    </th>
                                    <th className="fb-regular table__head">
                                        Сумма
                                    </th>
                                    <th className="fb-regular table__head">
                                        Кошелёк
                                    </th>
                                    <th className="fb-regular table__head">
                                        Создано
                                    </th>
                                    <th className="fb-regular table__head">
                                        Статус
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {!loading &&transactions?transactions.map((transaction)=><tr key={uniqid()}>
                                    <td>
                                        <p className="fb-regular ">{transaction.type}</p>
                                    </td>
                                    <td>
                                        <p className={`fb-regular  ${transaction.type=="Deposit"||transaction.type=="Bonus"?"text-bullish":"text-bearish"}`}>{transaction.type=="Deposit"||transaction.type=="Bonus"?"+":"-"} {transaction.amount}$</p>
                                    </td>
                                    <td>
                                        <p className="fb-regular">
                                           {transaction.type=="Deposit"?transaction.wallet.wallet:transaction.withdrawWallet}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="fb-regular ">
                                           {convertDateFormat(transaction.date)}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="fb-regular " style={{color:transaction.status==0?"yellow":transaction.status==2?"green":transaction.status==3?"red":"yellow"}}>
                                            {transaction.status==0?"В процессе":transaction.status==2?"Подтверждено":transaction.status==3?"Отклонено":"В процессе"}
                                        </p>
                                    </td>
                                    <td>
                                        {getLinkTransaction(transaction._id,transaction.type)?<Link className="fb-regular " to={getLinkTransaction(transaction._id,transaction.type)}>
                                           Просмотреть
                                        </Link>:null}
                                        
                                    </td>
                                </tr>).reverse():null}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="table__vignette" />
        </div>
    );
};

export default WalletActions;


function convertDateFormat(inputDate) {
        // Create a Date object from the input string
        const date = new Date(inputDate);
    
        // Extract date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        // Construct the formatted date string
        const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;
    
        return formattedDate;
}



const getLinkTransaction=(id,type)=>{

    switch(type){
        case "Deposit":
            return `?deposit_id=${id}`
            break;
        case "Withdraw":
            return `?withdraw_id=${id}`;
            break;
        default:return null;
    }

}