import React, { useEffect, useState } from "react";
import "../css/pages/sign-up.css";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../Redux/Actions/userActions";
import Toast from "./Toast/toast";
import { toast } from "react-toastify";
import { Link, useLocation, useSearchParams } from "react-router-dom";
const SignUp = () => {
    const Toastobjects = {
        pauseOnFocusLoss: false,
        draggable: false,
        pauseOnHover: false,
        autoClose: 2000,
    };

    const location = useLocation();
  
    const queryParameters = new URLSearchParams(location.search);
    const [referralCode, setReferralCode] = useState("");
    if(queryParameters.get("referral")!==null&&!referralCode){
        setReferralCode(queryParameters.get("referral"));
    }
    

    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [alreadyExistsUser, setAlreadyExistsUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    useEffect(() => {
        document.documentElement.classList.add("sign-up-page");
    }, []);
    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, userInfo } = userRegister;
    const userLogin=useSelector(state=>state.userLogin);

    useEffect(() => {
        if (userInfo!=null) {
            window.location.href = "/dashboard";
        } else if (error) {
            setAlreadyExistsUser(true);

            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(error, Toastobjects);
                setTimeout(() => {
                    setAlreadyExistsUser(false);
                }, 3000);
            }
        }
    }, [userRegister]);
  
    const submitHandler = (e) => {
        e.preventDefault();
      
        dispatch(
            register({ firstName, lastName, email, password, referralCode })
        );
    };
   
    if(userInfo){
        window.location.href="/dashboard";
    }
    return (
        <div className="sign-up">
            <Toast />
            <div className="sign-up-card">
                <div className="sign-up__heading">
                    <img
                        src="/assets/media/images/icons/logo-signin.svg"
                        alt=""
                        className="logo aos-init aos-animate"
                        data-aos="fade-up"
                    />
                    <p
                        className="fb-sm sign-up__title aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={50}
                    >
                        Let’s get started by filling out the form
                    </p>
                </div>
                <form onSubmit={submitHandler} className="sign-up__forms">
                    <div className="forms-name">
                        <div
                            className="forms-group aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <input
                                type="text"
                                className="form-control"
                                id="first-name"
                                name="first-name"
                                required
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                        <div
                            className="forms-group aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={150}
                        >
                            <input
                                type="text"
                                className="form-control"
                                id="last-name"
                                required
                                name="last-name"
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        className="forms-group aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        <input
                            type="email"
                            className="form-control"
                            style={
                                alreadyExistsUser
                                    ? { border: "1px solid red", color: "red" }
                                    : {}
                            }
                            id="email"
                            required
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div
                        className="forms-group aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={250}
                    >
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="password"
                            required
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            className="form-group__password-toggle js-form-group__password-toggle"
                            data-password="password"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <div
                        className="forms-group aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={250}
                    >
                        <input
                            type="text"
                            className="form-control"
                            id="referral"
                            name="referral"
                            placeholder="Enter your referral code ( optional )"
                            value={referralCode}
                            onChange={(e) => setReferralCode(e.target.value)}
                        />
                    </div>
                    <div
                        className="tnc aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={300}
                    >
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="terms-condition"
                                name="terms-condition"
                                checked={termsAgreed}
                                onChange={(e) =>
                                    setTermsAgreed(e.target.checked)
                                }
                                required
                            />
                            <label
                                className="form-check-label fb-sm"
                                htmlFor="terms-condition"
                            >
                                I agree to the Terms and Conditions and the
                                Trading Risk Notice
                            </label>
                        </div>
                    </div>
                    <input
                        type="submit"
                        className="btn btn-primary btn-pill btn-submit aos-init aos-animate"
                        defaultValue="Sign Up Now"
                        data-aos="zoom-in-up"
                        data-aos-delay={300}
                    />
                    <p
                        className="fb-sm sign-up-info aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={350}
                    >
                        Already have an account?{" "}
                        <Link to="/sign-in" className="sign-up-link">
                            Sign in now
                        </Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
