import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
import Toast from "../Toast/toast";
const WithdrawDefault = (props) => {

const {withdrawHandler,
        loadingTariff,
        setWallet,
        wallet,
        amount,
        onOutsideClickHandler,
        success,
        getReceiveAmount,
        tariff,
        setAmount
}=props;

    return (
        <React.Fragment>
            <Toast/>
    <div className={`modal-window`}>
            <OutsideAlerter
                className={`advance-card advance-card--type-1-v1 aos-init aos-animate ${
                    success ? "blur-modal" : ""
                }`}
                onOutsideClick={() => {
                    onOutsideClickHandler();
                }}
            >
                <div className="advance-card__title">
                    <h6 className="advance-card__title-text">Withdraw</h6>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        Withdrawal every Sunday at 12:00 UTC
                    </p>
                </div>

                <div className="advance-card__forms-currency">
                    <div className="advance-card__forms-currency--pay">
                        <p className="advance-card__forms-currency__title">
                            Get
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="number"
                                    placeholder="Enter amount"
                                    min={0}
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-2"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/usd.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="advance-card__forms-currency--get">
                        <p className="advance-card__forms-currency__title">
                            Wallet
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="text"
                                    placeholder={"Enter your wallet"}
                                    value={wallet}
                                    onChange={(e) => setWallet(e.target.value)}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-1"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/logo-usdt.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        Will remove from balance: {amount} USDT
                    </p>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        Комиссия компании по вашему тарифу: {!loadingTariff && tariff?`${tariff.name} (${tariff.commission}%)`:"Standart (20%)"} 
                    </p>
                </div>
                <div className="advance-card__title">
                    <p>
                        Receives to the wallet:{" "}
                        {!loadingTariff && tariff
                            ? getReceiveAmount(amount, tariff)
                            : "..."}{" "}
                        USDT
                    </p>
                </div>
                <button
                    className="advance-card__button btn btn-primary btn-pill"
                    onClick={withdrawHandler}
                >
                    Withdraw
                </button>
            </OutsideAlerter>
        </div>
        </React.Fragment>
    
    );
};

export default WithdrawDefault;
