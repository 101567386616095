import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <div
                        className="footer__item footer-item-main aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <img
                            className="footer-item-main__logo"
                            src="/assets/media/images/logo-full.png"
                            alt="Cryptolly"
                        />
                        <p className="fb-lg footer-item-main__desc">
                            Your best crypto partner.
                        </p>
                        <div className="footer-item-main__socmed">
                            <a href="#" className="footer-socmed">
                                <img
                                    className="footer-item-main__logo"
                                    src="/assets/media/images/icons/logo-linkedin.svg"
                                    alt="Linkedin"
                                />
                            </a>
                            <a href="#" className="footer-socmed">
                                <img
                                    className="footer-item-main__logo"
                                    src="/assets/media/images/icons/logo-facebook.svg"
                                    alt="Facebook"
                                />
                            </a>
                            <a href="#" className="footer-socmed">
                                <img
                                    className="footer-item-main__logo"
                                    src="/assets/media/images/icons/logo-ig.svg"
                                    alt="Instagram"
                                />
                            </a>
                        </div>
                    </div>
                    <div
                        className="footer__item footer-item-quick-links aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={100}
                    >
                        <h6 className="footer__item-title">Pages</h6>
                        <div className="footer-quick-links">
                            <Link
                                to="/"
                                className="fb-md footer-quick-links__link"
                            >
                                Home
                            </Link>
                            <Link
                                href="/dashboard"
                                className="fb-md footer-quick-links__link"
                            >
                                Dashboard
                            </Link>
                            <Link
                                to="/wallets"
                                className="fb-md footer-quick-links__link"
                            >
                                Wallets
                            </Link>
                            <Link
                                    to="/profile"
                                    className="fb-md footer-quick-links__link"
                                >
                                    Profile
                                </Link>
                            <Link
                                href="/sign-in"
                                className="fb-md footer-quick-links__link"
                            >
                                Sign In
                                
                            </Link>
                            <Link
                                to="/logout"
                                className="fb-md footer-quick-links__link"
                            >
                                Sign Out
                            </Link>
                            <Link
                                to="/privacy-policy"
                                className="fb-md footer-quick-links__link"
                            >
                                Privacy Policy
                            </Link>
                            <Link
                                to="/terms"
                                className="fb-md footer-quick-links__link"
                            >
                                Terms of Service
                            </Link>
                        </div>
                    </div>
                    <div
                        className="footer__item footer-item-updates aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        <h6 className="footer__item-title">
                            Submit for updates.
                        </h6>
                        <p className="fb-md footer-item-updates__desc">
                            Subscribe to get update and notify our exchange and
                            products
                        </p>
                        <div className="mini-form-sm__box">
                            <form className="mini-form">
                                <input
                                    type="text"
                                    className="form-control form-control-sm mini-form__input"
                                    placeholder="Enter you email address"
                                    autoComplete="off"
                                />
                                <input
                                    type="submit"
                                    className="btn btn-primary btn-sm mini-form__submit"
                                    defaultValue="Send"
                                />
                            </form>
                        </div>
                    </div>
                </div>
                <div
                    className="footer__foot aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay={300}
                >
                    <p className="fb-md footer-foot__copyright">
                        p2p-arbitraz.com ©. All rights reserved.
                    </p>
                    <div className="footer-foot__tos-privacy">
                        <Link to="/terms" className="fb-md footer-foot__link">
                            Terms of Service
                        </Link>
                        <span className="separator" />
                        <Link
                            to="/privacy-policy"
                            className="fb-md footer-foot__link"
                        >
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
