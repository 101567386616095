import React, { useState } from "react";
import { uniqid } from "../../../helpers/uniqid";
import AccordionItem from "./accordionItem";

const faqArray = [
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
    {
        title: "Why is the moon sometimes out during the day?",
        text: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
        Elementum sagittis vitae et leo duis ut. Ut
        tortor pretium viverra suspendisse potenti.`,
        opened: false,
    },
];

const FAQ = () => {
    const [faqs, setFaqs] = useState(faqArray);

        

        const toggleAccordion=(index)=>{
                faqs[index].opened=!faqs[index].opened;
                setFaqs([...faqs]);
        }

    return (
        <section className="faq" id="faq">
            <div className="container">
                <h2 style={{ textAlign: "center" }}>FAQ</h2>
                <div className="accordion" style={{ marginTop: "2rem" }}>
                   <AccordionItem title={faqArray[0].title} text={faqArray[0].text}/>
                   <AccordionItem title={faqArray[1].title} text={faqArray[1].text}/>
                   <AccordionItem title={faqArray[2].title} text={faqArray[2].text}/>
                   <AccordionItem title={faqArray[3].title} text={faqArray[3].text}/>
                   <AccordionItem title={faqArray[4].title} text={faqArray[4].text}/>
                   <AccordionItem title={faqArray[5].title} text={faqArray[5].text}/>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
