import React, { Fragment } from "react";
import "../../css/pages/landing-page.css";
import GridGraph from "./elements/gridGraph";
import Feature from "./elements/feature";
import Assets from "./elements/assets";
import OurTeam from "./elements/ourteam";
import FAQ from "./elements/faq";
import HowToStart from "./elements/howtostart";
import HowWorks from "./elements/howworks";
import Footer from "./elements/footer";
import { useNavigate } from "react-router";
import Calculator from "./elements/calculator";
import HowMany from "./elements/howmany";

export const Home = () => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <section className="hero">
                <div className="hero__background hero__background--gradient">
                    <img
                        className="hero__gradient-bg"
                        src="/assets/media/images/landing-page-gradient-bg.svg"
                        alt="Background Gradient Hero"
                    />
                </div>
                <div className="hero__background hero__background--pattern">
                    <img
                        className="hero__pattern-bg"
                        src="/assets/media/images/landing-page-pattern.svg"
                        alt="Background Pattern Hero"
                    />
                </div>
                <div className="hero__background--mobile hero__background--gradient">
                    <img
                        className="hero__gradient-bg"
                        src="/assets/media/images/mobile-responsive/mobile-landing-page-hero-gradient.png"
                        alt="Background Gradient Hero"
                    />
                </div>
                <div className="hero__background--mobile hero__background--pattern">
                    <img
                        className="hero__pattern-bg"
                        src="/assets/media/images/mobile-responsive/mobile-landing-page-hero-pattern.png"
                        alt="Background Pattern Hero"
                    />
                </div>
                <div className="container">
                    <div
                        className="hero__content aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <h1 className="hero__title mx-auto">
                        Что такое криптовалютный арбитраж?
                        </h1>
                        <p className="fb-lg mx-auto hero__subtitle">
                        Криптовалютный арбитраж - это стратегия торговли, при которой вы можете заработать на разнице в ценах на один и тот же актив на разных биржах. Наше программное обеспечение автоматически анализирует рынок и проводит сделки за вас, обеспечивая максимальную прибыль.
                        </p>
                        <button className="btn btn-primary btn-lg btn-pill mx-auto hero__button" onClick={()=>navigate("/sign-up")}>
                            Начать
                        </button>
                    </div>
                </div>
            </section>
            <GridGraph />
            {/* Start::Feature */}
            <Feature />
            <Assets />
            <HowWorks />
            <HowMany/>
            <OurTeam />
            <HowToStart />
            <FAQ />
        <Calculator/>
            <Footer />
        </Fragment>
    );
};

export default Home;
