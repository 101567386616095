import React from "react";
import {ToastContainer} from "react-toastify";

const Toast=()=>{
        return (
                <div style={{zIndex:"10000000000"}}>
                        <ToastContainer position="top-right"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        
                        />
                </div>
        )
}

export default Toast;