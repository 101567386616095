import React from "react";

const HowToStart = () => {
    return (
        <section className="how-to-start">
                <div class="how-to-start__background how-to-start__background--gradient"><img class="how-to-start__gradient-bg" src="/assets/media/images/landing-page-why-choose-us-gradient.svg" alt="Background Gradient Feature"/></div>
            <div className="how-to-start__background how-to-start__background--pattern">
                <img
                    className="how-to-start__pattern-bg"
                    src="/assets/media/images/landing-page-why-choose-us-pattern.png"
                    alt="Background Pattern Feature"
                />
            </div>
            <div className="container">
                <h2 className="how-start_header" style={{paddingTop:"4rem"}}>HOW TO START</h2>
                <div className="how-start_list">
                    <div className="how-start_list-item">
                        <div className="how-start_list-item_image-container">
                            <img
                                src="/assets/media/images/process-2-1.png"
                                className="how-start_list-item_image"
                            />
                        </div>
                        <h4>Download App</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. At, nihil.
                        </p>
                    </div>
                    <div className="how-start_list-item">
                        <div className="how-start_list-item_image-container">
                            <img
                                src="/assets/media/images/process-2-1.png"
                                className="how-start_list-item_image"
                            />
                        </div>
                        <h4>Download App</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. At, nihil.
                        </p>
                    </div>
                    <div className="how-start_list-item">
                        <div className="how-start_list-item_image-container">
                            <img
                                src="/assets/media/images/process-2-1.png"
                                className="how-start_list-item_image"
                            />
                        </div>
                        <h4>Download App</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. At, nihil.
                        </p>
                    </div>
                    <div className="how-start_list-item">
                        <div className="how-start_list-item_image-container">
                            <img
                                src="/assets/media/images/process-2-1.png"
                                className="how-start_list-item_image"
                            />
                        </div>
                        <h4>Download App</h4>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. At, nihil.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowToStart;
