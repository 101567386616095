import React from "react";

const TriangleWindow = (props) => {
    return (
        <div style={{ position: "absolute" }} className="triangle_window">
            This is a triangle trade. This exchange is performed automatically
            by the server. All exchanges are carried out in the direction of the
            USDT. For example, SOL-USDT, USDT-BTC, BTC-SOL. Due to slight
            differences in exchange rates, the exchange is circled.
        </div>
    );
};

export default TriangleWindow;
