import React from "react";
import "./howmany.css";
const HowMany=()=>{
        return (
<section className="why-choose-us why-choose-us--v3">
<div className="container features aos-init aos-animate" data-aos="zoom-in-up" data-aos-delay={500}>
  <div className="features__items aos-init aos-animate" data-aos="zoom-in-up" data-aos-delay={600}>
    <p className="fd-xl features-items__value">1+ год</p>
    <p className="fb-lg features-items__name">На рынке</p>
  </div>
  <div className="features__items aos-init aos-animate" data-aos="zoom-in-up" data-aos-delay={700}>
    <p className="fd-xl features-items__value">900+</p>
    <p className="fb-lg features-items__name">Пользователей</p>
  </div>
  <div className="features__items aos-init aos-animate" data-aos="zoom-in-up" data-aos-delay={800}>
    <p className="fd-xl features-items__value">7m$+</p>
    <p className="fb-lg features-items__name">Оборот средств</p>
  </div>
</div>



</section>
        )
}

export default HowMany;