import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";
const DepositCreated=(props)=>{
        const {transaction,loadingConfirm,loading,confirmDepositHandler,loadingCreate, loadingTransaction,closeModalHandler}=props;


        return (  <div className="modal-window">
                <OutsideAlerter
                    className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
                    data-aos="fade-up"
                    onOutsideClick={() => closeModalHandler(false)}
                >
                        
                    <div className="advance-card__title">
                        <h6 className="advance-card__title-text">Deposit</h6>
                    </div>
                    <div
                        className={`advance-card__price ${
                            loading || loadingConfirm ? "blur-loading2x" : ""
                        }`}
                    >
                        <div className="advance-card__price-crypto">
                            <p className="advance-card__price-crypto-value">
                                1
                            </p>
                            <p className="advance-card__price-crypto-name">
                                {transaction.wallet.symbol}
                            </p>
                        </div>
                        <img
                            src="../assets/media/images/icons/arrow-right-white.svg"
                            alt=""
                            className="advance-card__price-arrow"
                        />
                        <div className="advance-card__price-currency">
                            <p className="advance-card__price-currency-value">
                                {transaction.wallet.rate}
                            </p>
                            <p className="advance-card__price-currency-name">
                                USD
                            </p>
                        </div>
                    </div>
                    <div
                        className={`advance-card__forms-currency ${
                            loading || loadingConfirm ? "blur-loading2x" : ""
                        }`}
                    >
                        <div
                            className={`advance-card__forms-currency--get ${
                                loadingTransaction ? "blur-loading2x" : ""
                            }`}
                        >
                            <p className="advance-card__forms-currency__title">
                                Pay
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="text"
                                        value={`${transaction.amount} ${transaction.currency.symbol}`}
                                        className="form-control forms-currency__value"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`advance-card__forms-currency--pay ${
                                loadingTransaction ? "blur-loading2x" : ""
                            }`}
                        >
                            <p className="advance-card__forms-currency__title">
                                To
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="text"
                                        value={transaction.wallet.wallet}
                                        className="form-control forms-currency__value"
                                    />
                                </div>
                            </div>
                        </div>
                        {loadingCreate ? (
                            <img
                                src="https://i.gifer.com/YlWC.gif"
                                style={{ width: "50%", position: "absolute" }}
                            />
                        ) : null}
                    </div>
                    <button
                        className={`advance-card__button btn btn-primary btn-pill ${
                            loading || loadingCreate ? "blur-loading2x" : ""
                        }`}
                        disabled={loading || loadingCreate}
                        onClick={() => confirmDepositHandler(transaction._id)}
                    >
                        Completed
                    </button>
                </OutsideAlerter>
            </div>)
}

export default DepositCreated;