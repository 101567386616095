import { makeAutoObservable } from "mobx";

class Store {
    variables = {
        depositModalOpened: false,
        withdrawModalOpened: false,
        growthOpened: false,
        transferConfirmModalOpened:false,
        deleteAccountModalOpened:false
    };

    constructor() {
        makeAutoObservable(this);
    }

    setDepositModalOpened = (value) => {
        this.variables = { ...this.variables, depositModalOpened: value };
    
    };
    setWithdrawModalOpened = (value) => {
        this.variables = { ...this.variables, withdrawModalOpened: value };
    };
    setGrowthOpened = (value) => {
        this.variables = { ...this.variables, growthOpened: value };
    };
    setTransferOpened=(value)=>{
        this.variables={...this.variables,transferConfirmModalOpened:value};
    }
    setDeleteAccountModalOpened=(value)=>{
        this.variables={...this.variables,deleteAccountModalOpened:value};
    }
}

export default new Store();
