export const checkIfOneIsTrue=(variables)=>{
      
                // check if one key of parameter is true
                for(var key in variables){
                    if(variables[key]){
                        return true;
                    }
                }
            
                return false;
            
};