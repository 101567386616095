import axios from "axios";

export function getDataOrFetch(apiUrl, localStorageName, config = {}) {
   
    const fromLocalStorage = getFromLocalStorage(localStorageName);
 
 
       
        setTimeout(async () => {
            try {
                const { data } = await axios.get(apiUrl, config);
              
                setToLocalStorage(localStorageName, data);
            } catch (error) {
                console.error(error);
            }
        }, 0);
    
  
    return fromLocalStorage || fetchFromServer(apiUrl, localStorageName, config);
}

export async function fetchFromServer(apiUrl, localStorageName, config) {
    try {
        const { data } = await axios.get(apiUrl, config);
        setToLocalStorage(localStorageName, data);
        return data;
    } catch (error) {
        throw error;
    }
}

export function getFromLocalStorage(localStorageName) {
    var temporaryTurnedOff = false;
    if (temporaryTurnedOff) {
        return null;
    }
    return localStorage.getItem(localStorageName)
        ? JSON.parse(localStorage.getItem(localStorageName))
        : null;
}

export function setToLocalStorage(localStorageName, data) {
    localStorage.setItem(localStorageName, JSON.stringify(data));
    
}

export async function fetchFromServerNoSave(apiUrl,config){
    const { data } = await axios.get(apiUrl, config);
    return data;
}
