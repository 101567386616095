import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import {
    getGrowthUser,
    getUserDonutStats,
    getUserDonutStats7D,
} from "../../Redux/Actions/userActions";
import { getIconFromServer } from "../../helpers/getImageFromServer";

const CoinsStats = () => {
    const dispatch = useDispatch();

    const [coinsStatsArr, setCoinsStatsArr] = useState([]);

    const userGrowth = useSelector((state) => state.userGrowth);
    const { loading: loadingGrowth, error: errorGrowth, growth } = userGrowth;
    const userDonutStats7D = useSelector((state) => state.userDonutStats7D);

    const { loading: loadingStats, error: errorStats, stats } = userDonutStats7D;

    useEffect(() => {
        dispatch(getUserDonutStats7D());
        dispatch(getGrowthUser({ period: "month" }));
    }, [dispatch]);

    useEffect(() => {
        if (
            !loadingGrowth &&
            !loadingStats &&
            typeof loadingGrowth !== "undefined" &&
            typeof loadingStats !== "undefined"
        ) {
            const convertedData = convertDatastoCardChart(growth, stats);
        
            const chartInfo = [];
            convertedData.forEach((data) => {
                chartInfo.push({
                    ...cardChart(
                        "Desktops",
                        data.data,
                        "#11CABE",
                        data.categories
                    ),
                    name: data.name,
                    symbol: data.symbol,
                    stats:data.stats
                });
            });
            setCoinsStatsArr(chartInfo);
        }
    }, [userGrowth, userDonutStats7D]);

 
    return (
        <div className="assets aos-init aos-animate" data-aos="zoom-in-up">
            <div className="assets__carousel slick-initialized slick-slider">
                <div className="slick-list draggable">
                    <div
                        className="slick-track"
                        style={{
                            opacity: 1,
                            width: "1364px",
                            transform: "translate3d(0px, 0px, 0px)",
                        }}
                    >
                        {coinsStatsArr.length > 0
                            ? coinsStatsArr.map((coin) => {
                                  try {
                                      return (
                                          <div
                                              className="standard-card standard-card--type-3-v1 assets__item slick-slide slick-current slick-active"
                                              style={{ width: "325px" }}
                                              tabIndex={0}
                                              data-slick-index={0}
                                              aria-hidden="false"
                                          >
                                              <div className="container">
                                                  <div className="standard-card__content">
                                                      <div className="standard-card__content-graph">
                                                          <img
                                                              src={getIconFromServer(coin.stats.icon)}
                                                              alt=""
                                                              className="standard-card__content-image"
                                                          />
                                                          <div className="standard-card__content-chart-wrapper">
                                                              <div
                                                                  id="standard-card__content-chart-3"
                                                                  className="standard-card__content-chart-render"
                                                                  style={{
                                                                      minHeight:
                                                                          "115px",
                                                                  }}
                                                              >
                                                                  <Chart
                                                                      options={
                                                                          coin.options
                                                                      }
                                                                      series={
                                                                          coin.series
                                                                      }
                                                                      height={
                                                                          100
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="standard-card__content-percentage">
                                                          <img
                                                              className="standard-card__content-arrow"
                                                              src="../assets/media/images/icons/green-arrow-up.svg"
                                                              alt=""
                                                          />
                                                          <p className="standard-card__content-value text-bullish">
                                                              {(coin.stats.income24h*100).toFixed(2)}%
                                                          </p>
                                                      </div>
                                                      <div className="standard-card__content-desc">
                                                          <p className="standard-card__content-desc-currency">
                                                              {coin.name}
                                                          </p>
                                                          <div className="standard-card__content-desc-prices">
                                                              <p className="standard-card__content-desc-price">
                                                                  USD {(coin.stats.income-coin.stats.expenses).toFixed(2)}
                                                              </p>
                                                              <p className="standard-card__content-desc-token">
                                                                  Profit 7D
                                                              </p>
                                                          </div>
                                                         
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      );
                                  } catch (error) {
                                  
                                      return null;
                                  }
                              })
                            : null}
                    </div>
                </div>
            </div>
            <div className="slick__pagination">
                <div className="assets-carousel__arrows">
                    <a
                        href="#"
                        className="assets-carousel__prev slick-arrow slick-disabled"
                        style={{ display: "inline" }}
                        aria-disabled="true"
                    >
                        <img
                            src="../assets/media/images/icons/light-circle-arrow-left.svg"
                            alt="Previous"
                        />
                    </a>
                    <a
                        href="#"
                        className="assets-carousel__next slick-arrow"
                        style={{}}
                        aria-disabled="false"
                    >
                        <img
                            src="../assets/media/images/icons/light-circle-arrow-right.svg"
                            alt="Next"
                        />
                    </a>
                </div>
                <div className="assets-carousel__pagination slick__pagination--dots">
                    <div className="assets-carousel__slick-slider-dots slick-slider-dots" />
                </div>
            </div>
        </div>
    );
};

export default CoinsStats;

const convertDatastoCardChart = (growth, stats) => {
    let currencies = [
        /* {
        name:"Bitcoin"    ,
        symbol:"BTC",
        
        categories:[date,date,date,date,date,...],
        data:[]



     } */
    ];

    growth.forEach((growthItem) => {
        growthItem.currencies.forEach((currenciesItem) => {
            // if not found add element
            if (
                currencies.findIndex(
                    (item) => item.name == currenciesItem.name
                ) < 0
            ) {
                currencies.push({
                    name: currenciesItem.name,
                    symbol: currenciesItem.symbol,
                    data: [],
                    categories: [],
                    stats: stats.incomeCurrencies.find(
                        (item) => item.name == currenciesItem.name
                    ),
                });
            }

            const indexOfCurrency = currencies.findIndex(
                (item) => item.name == currenciesItem.name
            );

            currencies[indexOfCurrency].data.push(currenciesItem.income24h);
            currencies[indexOfCurrency].categories.push(
                growthItem.date.split("-")[0]
            );
        });
    });

    return currencies;
};

function cardChart(name, data, color, categories) {
    return {
        series: [
            {
                name: name,
                data: data,
            },
        ],
        options: {
            chart: {
                height: 100,
                type: "area",
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
                stacked: false,
            },
            dataLabels: {
                enabled: false,
            },
            colors: [color],
            stroke: {
                width: 1,
                curve: "straight",
            },
            fill: {
                gradient: {
                    type: "vertical",

                    shadeIntensity: 1,
                    opacityFrom: 0.6,
                    opacityTo: 0,
                    stops: [0, 80, 100],
                },
            },
            grid: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            xaxis: {
                categories: categories,
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { show: false },
            },
            yaxis: {
                axisBorder: { show: false },
                axisTicks: { show: false },
                labels: { show: false },
            },
        },
    };
}
