import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Actions/userActions";
import {Navigate} from "react-router-dom";
const Logout=()=>{
        const dispatch=useDispatch();

        dispatch(logout());
        localStorage.clear();
        return (<Navigate to="/" replace/>);
}

export default Logout;