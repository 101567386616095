import React from "react";

const OurTeam = () => {
    return (
        <section className="our-team" id="our-team">
            <div class="our-team__background our-team__background--gradient">
                <img
                    class="our-team__gradient-bg"
                    src="/assets/media/images/landing-page-why-choose-us-gradient.svg"
                    alt="Background Gradient Feature"
                />
            </div>
            <div className="our-team__background our-team__background--pattern">
                <img
                    className="our-team__pattern-bg"
                    src="/assets/media/images/landing-page-why-choose-us-pattern.png"
                    alt="Background Pattern Feature"
                />
            </div>
            <div className="container">
                <h2 className="our-team__header">Наша команда</h2>
                <p className="our-team__description">
                Давайте поближе узнаем друг-друга
                </p>

                <div className="our-team__list">
                    <div className="our-team__list-item">
                        <img
                            src="/assets/media/images/team/member-1.png"
                            className="our-team__list-image"
                        />
                        <h3 className="our-team__list-item_name">Adam Smith</h3>
                        <p className="our-team__list-item_title">
                        CEO & CTO
                        </p>
                    </div>
                    <div className="our-team__list-item">
                        <img
                            src="/assets/media/images/team/member-2.png"
                            className="our-team__list-image"
                        />
                        <h3 className="our-team__list-item_name">Noah Wilson</h3>
                        <p className="our-team__list-item_title">
                        Business Development Director
                        </p>
                    </div>
                    <div className="our-team__list-item">
                        <img
                            src="/assets/media/images/team/member-3.png"
                            className="our-team__list-image"
                        />
                        <h3 className="our-team__list-item_name">Olivia White</h3>
                        <p className="our-team__list-item_title">
                        Development Manager
                        </p>
                    </div>
                    <div className="our-team__list-item">
                        <img
                            src="/assets/media/images/team/member-4.png"
                            className="our-team__list-image"
                        />
                        <h3 className="our-team__list-item_name">Emma Clark</h3>
                        <p className="our-team__list-item_title">
                        Marketing Manager
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurTeam;
