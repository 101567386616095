import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
    getUserDetails,
    getUserNotifications,
} from "../Redux/Actions/userActions";
import { getImageFromServer } from "../helpers/getImageFromServer";
import { observer } from "mobx-react-lite";
import mobxStore from "../mobx/mobxStore";
import OutsideAlerter from "../helpers/outsideAlerter";
import { uniqid } from "../helpers/uniqid";
import { HashLink } from "react-router-hash-link";
import { checkIfOneIsTrue } from "../helpers/oneofvartrue";
const Header = observer(() => {
    const { variables } = mobxStore;
    const dispatch = useDispatch();
    const location = useLocation();
    const [noHeader, setNoHeader] = useState(false);
    const [mobileHeader, setMobileHeader] = useState(0);
    const userLogin = useSelector((state) => state.userLogin);
    const { error, userInfo } = userLogin;
    const [navbarOpened, setNavbar] = useState(false);
    const [notificationOpened, setNotificationOpened] = useState(false);
    const userDetails = useSelector((state) => state.userDetails);
    const { user, loading } = userDetails;
    const userNotifications = useSelector((state) => state.userNotifications);

    const { notifications, loading: loadingNotifications } = userNotifications;
    useEffect(() => {
        if (
            location.pathname == "/sign-in" ||
            location.pathname == "/sign-up"
        ) {
            setNoHeader(true);
        } else {
            setNoHeader(false);
        }
        setNavbar(false);
        setNotificationOpened(false);
    }, [location]);
    useEffect(() => {
        dispatch(getUserDetails());
        dispatch(getUserNotifications());
    }, [dispatch]);
    const setNotificationOpenedHandler = (value) => {
        setNotificationOpened(value);
        setNavbar(false);
    };

    const setNavbarHandler = (value) => {
        setNavbar(value);
    };

    const openCloseMobileMenu = () => {
        if (mobileHeader === 0) {
            setMobileHeader(1);
        } else if (mobileHeader === 1) {
            setMobileHeader(0);
        }
    };

    if (noHeader) {
        return <></>;
    }

    return (
        <nav
            className={`navbar navbar-expand-lg header-navbar header-navbar-dashboard--v1 aos-init aos-animate ${
                checkIfOneIsTrue(variables) ? "blur-modal" : ""
            }`}
            data-aos="fade-down"
        >
            <div className="container container--dashboard-nav">
                <button
                    className="navbar-toggler ml-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={openCloseMobileMenu}
                >
                    <img src="/assets/media/images/icons/menu.svg" alt="MENU" />
                </button>
                <Link className="navbar-brand" to="/">
                    <img
                        className="navbar-brand__text"
                        src="/logo.png"
                        alt="P2P Arbitraz"
                        style={{ width: "190px" }}
                    />
                    <h4 className="navbar-brand__text--mobile">
                        {location.pathname == "/dashboard"
                            ? "Dashboard"
                            : "Wallet"}
                    </h4>
                </Link>
                <div
                    className={` navbar-collapse ${
                        mobileHeader === 0 ? "collapse" : "collapse show"
                    }`}
                    id="navbarSupportedContent"
                >
                    <div className="navbar-collapse__content">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${
                                        location.pathname == "/dashboard"
                                            ? "active"
                                            : ""
                                    }`}
                                    to="/dashboard"
                                >
                                    Дашборд
                                </Link>
                            </li>

                            <li
                                className={`nav-item ${
                                    location.pathname == "/wallet"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <Link className="nav-link" to="/wallet">
                                    Кошелёк
                                </Link>
                            </li>
                            <li
                                className={`nav-item ${
                                    location.pathname == "/exchange"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <Link className="nav-link" to="/exchange">
                                    Обмен
                                </Link>
                            </li>
                            <li
                                className={`nav-item ${
                                    location.pathname == "/#tariffs"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <HashLink className="nav-link" to="/#tariffs">
                                    Тарифы
                                </HashLink>
                            </li>
                            <li
                                className={`nav-item ${
                                    location.pathname == "/#our-team"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <HashLink className="nav-link" to="/#our-team">
                                    Наша команда
                                </HashLink>
                            </li>
                            <li
                                className={`nav-item ${
                                    location.pathname == "/#faq" ? "active" : ""
                                }`}
                            >
                                <HashLink className="nav-link" to="/#faq">
                                    FAQ
                                </HashLink>
                            </li>
                        </ul>
                    </div>
                </div>
                {userInfo ? (
                    <div className="navbar-right">
                        <OutsideAlerter
                            className={`navbar-notifications `}
                            onOutsideClick={() => setNotificationOpened(false)}
                        >
                            <img
                                src="/assets/media/images/icons/notifications.svg"
                                alt="notifications-icon"
                                className="navbar-notifications__image"
                                onClick={() => setNotificationOpened(true)}
                            />
                            <div
                                className={`navbar-notifications__dropdown ${
                                    notificationOpened ? "active" : ""
                                }`}
                            >
                                <p className="fb-regular fb-regular--bold notifications__title">
                                    Notifications
                                </p>
                                <div
                                    className="notifications__box"
                                    style={{
                                        maxHeight: "200px",
                                        scrollbarWidth: "none",
                                        overflow: "auto",
                                    }}
                                >
                                    {!loadingNotifications &&
                                    typeof loadingNotifications !==
                                        "undefined" &&
                                    notifications &&
                                    notifications.length ? (
                                        notifications.map((notification) => (
                                            <div
                                                className="notifications__item"
                                                key={uniqid()}
                                            >
                                                <img
                                                    src="/assets/media/images/notification-image.png"
                                                    alt=""
                                                    className="notifications__item-image"
                                                />
                                                <div className="notifications__item-message">
                                                    <p className="notifications__item-sender">
                                                        {notification.from}
                                                    </p>
                                                    <p className="notifications__item-text">
                                                        {notification.message}
                                                    </p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="notifications__item-message">
                                            Нет уведомлений
                                        </div>
                                    )}
                                </div>
                            </div>
                        </OutsideAlerter>

                        <OutsideAlerter
                            className="navbar-profile-menu"
                            onOutsideClick={() => setNavbar(false)}
                            onClick={() => setNavbarHandler(true)}
                        >
                            <p className="navbar-profile-menu__text">
                                {!loading
                                    ? user.firstName + " " + user.lastName
                                    : ""}
                            </p>
                            <img
                                src={
                                    !loading
                                        ? getImageFromServer(user.userAvatar)
                                        : "/assets/media/images/profile-photo.png"
                                }
                                alt="profile-photo-icon"
                                className="navbar-profile-menu__image"
                            />
                            <img
                                src="/assets/media/images/icons/arrow-down.svg"
                                alt="arrow-down-icon"
                                className="navbar-profile-menu__arrow"
                            />
                            <div
                                className={`navbar-profile-menu__dropdown ${
                                    navbarOpened ? "active" : ""
                                }`}
                            >
                                <div className="navbar-profile-menu__dropdown-item">
                                    <Link
                                        className="fb-regular dropdown-item__link"
                                        to="/profile"
                                    >
                                        Account
                                    </Link>
                                </div>
                                <Link
                                    className="navbar-profile-menu__dropdown-item fb-regular dropdown-item__link"
                                    to="/logout"
                                >
                                    Log Out
                                </Link>
                            </div>
                        </OutsideAlerter>
                    </div>
                ) : (
                    <div className="navbar-buttons navbar-buttons--dekstop">
                        <Link
                            to="/sign-in"
                            className="btn btn-secondary btn-sm"
                        >
                            Sign in
                        </Link>
                        <Link to="/sign-up" className="btn btn-primary  btn-sm">
                            Get Started
                        </Link>
                    </div>
                )}

                <div className="navbar-search-popup">
                    <input
                        type="text"
                        className="navbar-search-popup__input js-navbar-search-popup__input"
                        placeholder="Search"
                    />
                    <img
                        src="/assets/media/images/icons/search.svg"
                        alt="search-icon"
                        className="navbar-search-popup__image"
                    />
                    <img
                        src="/assets/media/images/icons/plus-icon.svg"
                        alt="cancel-icon"
                        className="navbar-search-popup__image--cancel js-navbar-search-popup__image--cancel"
                    />
                </div>
            </div>
        </nav>
    );
});

export default Header;
