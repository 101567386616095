import React, { useState } from "react";

const AccordionItem = (props) => {
    const { title, text } = props;

        const [opened,setOpened]=useState(false);

    return (
        <div className="accordion-item">
            <button aria-expanded={opened ? "true" : "false"} onClick={()=>setOpened(!opened)}>
                <span className="accordion-title">{title}</span>
                <span className="icon" aria-hidden="false"></span>
            </button>
            <div className="accordion-content">
                <p>{text}</p>
            </div>
        </div>
    );
};

export default AccordionItem;
