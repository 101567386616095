import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTarrifs } from "../../../Redux/Actions/userActions";

const Assets = () => {
    const dispatch = useDispatch();

    const tariffsList = useSelector((state) => state.tariffsList);

    const { loading, tariffs, error } = tariffsList;

    useEffect(() => {
        dispatch(getTarrifs());
    }, [dispatch]);
   
    return (
        <section className="assets assets--v3" id="tariffs">
            <div className="container">
                <div className="assets__container">
                    {/* Start::Table Section */}
                    <div
                        className="table-wrapper table--type-2 aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        <div className="table__title">
                            <h6 className="table__title-text">Market Table</h6>
                            <nav className="navbar">
                                <div
                                    className="table__title-tabs"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <p
                                            className="fb-regular fb-regular--bold table__title-tab active"
                                            id="pills-market-table-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-all"
                                            role="tab"
                                            aria-controls="pills-market-table-all"
                                            aria-selected="true"
                                        >
                                            All Assets
                                        </p>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <p
                                            className="fb-regular fb-regular--bold table__title-tab"
                                            id="pills-market-table-gainers-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-gainers"
                                            role="tab"
                                            aria-controls="pills-market-table-gainers"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Gainers
                                        </p>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <p
                                            className="fb-regular fb-regular--bold table__title-tab"
                                            id="pills-market-table-losers-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-losers"
                                            role="tab"
                                            aria-controls="pills-market-table-losers"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Losers
                                        </p>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <p
                                            className="fb-regular fb-regular--bold table__title-tab"
                                            id="pills-market-table-tradeble-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-tradeble"
                                            role="tab"
                                            aria-controls="pills-market-table-tradeble"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Tradeble
                                        </p>
                                    </li>
                                </div>
                            </nav>
                            <div className="dropdown table__dropdown">
                                <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    All Assets
                                    <img
                                        src="../assets/media/images/icons/arrow-down.svg"
                                        alt=""
                                    />
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li>
                                        <a
                                            className="dropdown-item active"
                                            href="#"
                                            id="pills-market-table-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-all"
                                            role="tab"
                                            aria-controls="pills-market-table-all"
                                            aria-selected="true"
                                        >
                                            All Assets
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            id="pills-market-table-gainers-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-gainers"
                                            role="tab"
                                            aria-controls="pills-market-table-gainers"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Gainers
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            id="pills-market-table-losers-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-losers"
                                            role="tab"
                                            aria-controls="pills-market-table-losers"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Losers
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            id="pills-market-table-tradeble-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-market-table-tradeble"
                                            role="tab"
                                            aria-controls="pills-market-table-tradeble"
                                            aria-selected="false"
                                            tabIndex={-1}
                                        >
                                            Tradeble
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div
                                className="tab-pane fade show active"
                                id="pills-market-table-all"
                                role="tabpanel"
                                aria-labelledby="pills-market-table-all-tab"
                            >
                                <div className="table-container">
                                    <table className="table table-content">
                                        <thead style={{ textAlign: "center" }}>
                                            <tr>
                                                <th
                                                    className="fb-regular table__head"
                                                    // colSpan={2}
                                                    style={{padding:"0 1rem"}}
                                                >
                                                    Название
                                                </th>
                                                <th className="fb-regular table__head"style={{padding:"0 1rem"}}>
                                                    Минимальный депозит
                                                </th>
                                                <th className="fb-regular table__head"style={{padding:"0 1rem"}}>
                                                    Доход с одного обмена
                                                </th>
                                                <th className="fb-regular table__head"style={{padding:"0 1rem"}}>
                                                    Комиссия бота (компании)
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {!loading&&typeof loading!=="undefined"?tariffs.map(tariff=><tr>
                                                
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        {tariff.name}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        {tariff.minimalBalance}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular text-bullish">
                                                    {tariff.growth.from}% - {tariff.growth.to}%
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market ">
                                                        {tariff.commission}%
                                                      
                                                    </p>
                                                </td>
                                            </tr>):null}
                                            
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-market-table-gainers"
                                role="tabpanel"
                                aria-labelledby="pills-market-table-gainers-tab"
                            >
                                <div className="table-container">
                                    <table className="table table-content">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="fb-regular table__head"
                                                    colSpan={2}
                                                >
                                                    Assets
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Last Price
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Market Cap
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Change
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Chart
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Trade
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-btc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BTC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Bitcoin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-eth.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ETH
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Ethereum
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-bnb.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BNB
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Binance Coin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        2.43%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-xrp.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            XRP
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        XRP
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        2.42%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-usdt.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            USDT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Tether
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.40%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-act.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ACT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Achain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.05%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-ogn.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            OGN
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Origin Protocol
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.22%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-itc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ITC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        IoT Chain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.23%
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-market-table-losers"
                                role="tabpanel"
                                aria-labelledby="pills-market-table-losers-tab"
                            >
                                <div className="table-container">
                                    <table className="table table-content">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="fb-regular table__head"
                                                    colSpan={2}
                                                >
                                                    Assets
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Last Price
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Market Cap
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Change
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Chart
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Trade
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-btc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BTC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Bitcoin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-eth.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ETH
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Ethereum
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-bnb.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BNB
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Binance Coin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        2.43%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-xrp.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            XRP
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        XRP
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        2.42%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-usdt.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            USDT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Tether
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.40%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-act.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ACT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Achain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.05%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-ogn.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            OGN
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Origin Protocol
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.22%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-itc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ITC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        IoT Chain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.23%
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-market-table-tradeble"
                                role="tabpanel"
                                aria-labelledby="pills-market-table-tradeble-tab"
                            >
                                <div className="table-container">
                                    <table className="table table-content">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="fb-regular table__head"
                                                    colSpan={2}
                                                >
                                                    Assets
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Last Price
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Market Cap
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Change
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Chart
                                                </th>
                                                <th className="fb-regular table__head">
                                                    Trade
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-btc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BTC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Bitcoin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-eth.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ETH
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Ethereum
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 53,260.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.25%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-bnb.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            BNB
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Binance Coin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 247.72
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        2.43%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-xrp.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            XRP
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        XRP
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.5657
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        2.42%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-usdt.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            USDT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Tether
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 0.9999
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.40%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-act.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ACT
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Achain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 40,355.12
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.05%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-ogn.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            OGN
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        Origin Protocol
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 3,152.93
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bearish">
                                                        0.22%
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table__assets">
                                                    <div className="table__assets-crypto">
                                                        <img
                                                            src="../assets/media/images/icons/logo-itc.svg"
                                                            alt=""
                                                            className="table__assets-crypto-icon"
                                                        />
                                                        <p className="fb-regular fb-regular--bold table__assets-crypto-abbr">
                                                            ITC
                                                        </p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__assets-name">
                                                        IoT Chain
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular">
                                                        USD 21,222.20
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="fb-regular table__market text-bullish">
                                                        0.23%
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="table__vignette" />
                    </div>
                    {/* End::Table Section */}
                </div>
            </div>
        </section>
    );
};

export default Assets;
