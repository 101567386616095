import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_RESET,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_TRANSACTIONS_REQUEST,
    USER_TRANSACTIONS_SUCCESS,
    USER_TRANSACTIONS_FAIL,
    USER_TRANSACTIONS_RESET,
    USER_DONUT_STATS_REQUEST,
    USER_DONUT_STATS_SUCCESS,
    USER_DONUT_STATS_FAIL,
    USER_DONUT_STATS_RESET,
    USER_GROWTH_REQUEST,
    USER_GROWTH_SUCCESS,
    USER_GROWTH_FAIL,
    USER_GROWTH_RESET,
    USER_BALANCE_STATS_REQUEST,
    USER_BALANCE_STATS_SUCCESS,
    USER_BALANCE_STATS_FAIL,
    USER_BALANCE_STATS_RESET,
    STATUS_REFERRAL_REQUEST,
    STATUS_REFERRAL_SUCCESS,
    STATUS_REFERRAL_FAIL,
    STATUS_REFERRAL_RESET,
    GET_TARIFF_REQUEST,
    GET_TARIFF_SUCCESS,
    GET_TARIFF_FAIL,
    GET_TARIFF_RESET,
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAIL,
    GET_NOTIFICATIONS_RESET,
    GET_TARIFFS_LIST_REQUEST,
    GET_TARIFFS_LIST_SUCCESS,
    GET_TARIFFS_LIST_FAIL,
    GET_TARIFFS_LIST_RESET,
    USER_WALLET_ACTIONS_REQUEST,
    USER_WALLET_ACTIONS_SUCCESS,
    USER_WALLET_ACTIONS_FAIL,
    USER_WALLET_ACTIONS_RESET,
    GET_CURRENCIES_REQUEST,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES_RESET,
    USER_DONUT_STATS7D_REQUEST,
    USER_DONUT_STATS7D_SUCCESS,
    USER_DONUT_STATS7D_FAIL,
    USER_DONUT_STATS7D_RESET,
} from "../Constans/userContains";
import axios from "axios";
import { serverLink } from "../../App";
import {  fetchFromServerNoSave, getDataOrFetch } from "../../helpers/optimizationFetch";

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });
        const { data } = await axios.post(`${serverLink}/api/user/login`, {
            email,
            password,
        });
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const logout = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_DETAILS_RESET });
};

export const register = (props) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST });
        const { data } = await axios.post(
            `${serverLink}/api/user/register`,
            props
        );
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const getUserDetails = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/profile`,
            "getUserDetails",
            config
        );
        //const { data } = await axios.get(`${serverLink}/api/user/profile`, config);
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: message,
        });
    }
};

export const updateUserProfile = (props) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(
            `${serverLink}/api/user/profile`,
            props,
            config
        );

        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: message,
        });
    }
};

export const getUserTransactions = (props={}) => async (dispatch, getState) => {
    try {
      //  console.log(props);
        const {
            allTransactions = true,
            onlyTriangles = false,
            priceEnabled = false,
            incomeFrom=0,
            incomeTo=100,
        } = props;

        dispatch({ type: USER_TRANSACTIONS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await fetchFromServerNoSave(
            `${serverLink}/api/user/transactions?allTransactions=${allTransactions}&onlyTriangles=${onlyTriangles}&priceEnabled=${priceEnabled}&incomeFrom=${incomeFrom}&incomeTo=${incomeTo}`,
          //  `getUserTransactions?allTransactions=${allTransactions}&onlyTriangles=${onlyTriangles}&priceEnabled=${priceEnabled}&incomeFrom=${incomeFrom}&incomeTo=${incomeTo}`,
            config
        );
        dispatch({ type: USER_TRANSACTIONS_SUCCESS, payload: data });
    } catch (error) {
        console.log(error);
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_TRANSACTIONS_FAIL,
            payload: message,
        });
    }
};

export const getUserDonutStats = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_DONUT_STATS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();
     
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };
      
        const data = await getDataOrFetch(
            `${serverLink}/api/user/stats`,
            "getUserDonutStats",
            config
        );
        dispatch({ type: USER_DONUT_STATS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_DONUT_STATS_FAIL,
            payload: message,
        });
    }
};

export const getUserDonutStats7D = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_DONUT_STATS7D_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/stats-7d`,
            "getUserDonutStats7D",
            config
        );
        dispatch({ type: USER_DONUT_STATS7D_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_DONUT_STATS7D_FAIL,
            payload: message,
        });
    }
};

export const getGrowthUser = (props) => async (dispatch, getState) => {
    try {
        const { period } = props;
        dispatch({ type: USER_GROWTH_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/growth?period=${period}`,
            `getGrowthUser?period=${period}`,
            config
        );
        dispatch({ type: USER_GROWTH_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_GROWTH_FAIL,
            payload: message,
        });
    }
};

export const getUserBalanceStats = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_BALANCE_STATS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/stats-balance`,
            "getUserBalanceStats",
            config
        );
        dispatch({ type: USER_BALANCE_STATS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_BALANCE_STATS_FAIL,
            payload: message,
        });
    }
};

export const getReferralStatus = () => async (dispatch, getState) => {
    try {
        dispatch({ type: STATUS_REFERRAL_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/referral-status`,
            "getReferralStatus",
            config
        );
        dispatch({ type: STATUS_REFERRAL_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: STATUS_REFERRAL_FAIL,
            payload: message,
        });
    }
};

export const getUserTariff = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_TARIFF_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/tariff`,
            "getUserTariff",
            config
        );
        dispatch({ type: GET_TARIFF_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_TARIFF_FAIL,
            payload: message,
        });
    }
};

export const getUserNotifications = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_NOTIFICATIONS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/notifications`,
            "getUserNotifications",
            config
        );
        dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_NOTIFICATIONS_FAIL,
            payload: message,
        });
    }
};

export const getTarrifs = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_TARIFFS_LIST_REQUEST });

        const data = await getDataOrFetch(
            `${serverLink}/api/user/tariffs`,
            "getTarrifs"
        );
        dispatch({ type: GET_TARIFFS_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_TARIFFS_LIST_FAIL,
            payload: message,
        });
    }
};

export const getUserWalletActions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_WALLET_ACTIONS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const data = await getDataOrFetch(
            `${serverLink}/api/user/wallet-actions`,
            "getUserWalletActions",
            config
        );
        dispatch({ type: USER_WALLET_ACTIONS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: USER_WALLET_ACTIONS_FAIL,
            payload: message,
        });
    }
};

export const getCurrenciesList = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_CURRENCIES_REQUEST });

        const data = await getDataOrFetch(
            `${serverLink}/api/user/currencies`,
            "getCurrenciesList"
        );
        dispatch({ type: GET_CURRENCIES_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        if (message === "Not authorized, token failed") {
            dispatch(logout());
        }
        dispatch({
            type: GET_CURRENCIES_FAIL,
            payload: message,
        });
    }
};
