import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTransactions } from "../../Redux/Actions/userActions";
import { getIconFromServer } from "../../helpers/getImageFromServer";
import { uniqid } from "../../helpers/uniqid";

const Transaction = () => {
    const dispatch = useDispatch();

    const userTransactions = useSelector((state) => state.userTransactions);
    const { loading, error, transactions } = userTransactions;

    useEffect(() => {
        dispatch(getUserTransactions());
    }, [dispatch]);

 

    return (
        <div
            className={`${
                typeof loading === "undefined" || loading
                    ? "blur-loading2x"
                    : ""
            } standard-card standard-card--type-5-v2 aos-init aos-animate`}
            data-aos="fade-up"
        >
            <div className="container">
                <div className="standard-card__head">
                    <h6 className="standard-card__title">Transaction</h6>
                </div>
                <div className="standard-card__content">
                    {transactions.map((transaction) => (
                        <div className="standard-card__content__list" key={uniqid()}>
                            <div className="standard-card__content__list-currency">
                                <img
                                    src={getIconFromServer(transaction.currency.icon)}
                                    alt=""
                                    className="standard-card__content__list-currency-icon"
                                />
                                <p className="standard-card__content__list-currency-name">
                                    {transaction.currency.symbol}
                                </p>
                            </div>
                            <div className="standard-card__content__list-status">
                                <p className="standard-card__content__list-status-name">
                                    {transaction.type}
                                </p>
                                <img
                                    src={transaction.type === "Send"||transaction.type === "Withdraw"?"/assets/media/images/icons/transaction-arrow-red.svg":"/assets/media/images/icons/transaction-arrow-green.svg"}
                                    
                                    alt=""
                                    className="standard-card__content__list-status-icon"
                                />
                            </div>
                            <div className="standard-card__content__list-price">
                                <p className="standard-card__content__list-price-value">
                                    ${transaction.amount.toFixed(2)}
                                </p>
                            </div>
                        </div>
                    ))}

                   
                  
                </div>
                <div className="standard-card__vignette" />
            </div>
        </div>
    );
};

export default Transaction;
