import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";

const DepositConfirmed=(props)=>{
const {setDepositModalOpened,loading,loadingTransaction,loadingConfirm,closeModalHandler}=props;

return (      <div className="modal-window">
        
        <OutsideAlerter
            className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
            data-aos="fade-up"
            onOutsideClick={() => closeModalHandler()}
        >
            <div className="advance-card__title">
                <h6 className="advance-card__title-text">Deposit</h6>
            </div>

            <div
                className={`advance-card__forms-currency ${
                    loading || loadingConfirm ? "blur-loading2x" : ""
                }`}
            >
                <div
                    className={`advance-card__forms-currency--get ${
                        loadingTransaction ? "blur-loading2x" : ""
                    }`}
                >
                    <p
                        className="advance-card__forms-currency__title"
                        style={{ color: "#ffffff" }}
                    >
                        Receipt of funds confirmed
                    </p>
                </div>
            </div>
        </OutsideAlerter>
    </div>)

};

export default DepositConfirmed;
