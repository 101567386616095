import React, { useEffect } from "react";
import "../../css/pages/wallet.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../Redux/Actions/userActions";
import Transactions from "../exchange/transactions";
import BalanceDetails from "./balanceDetail";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";
import { makeRefTransfer } from "../../Redux/Actions/transactionsActions";
import Toast from "../../component/Toast/toast";
import { useState } from "react";
import Chart from "react-apexcharts";
import WalletActions from "./walletActions";
import { checkIfOneIsTrue } from "../../helpers/oneofvartrue";
const Wallet = observer((props) => {
    const {toastId,toast}=props;
    const dispatch = useDispatch();
    const { variables, setDepositModalOpened, setWithdrawModalOpened,setTransferOpened } =
        mobxStore;
    const userDetails = useSelector((state) => state.userDetails);
    const makeTransfer = useSelector((state) => state.makeTransfer);
    const {
        loading: transferLoading,
        success: transferSuccess,
        error: transferError,
    } = makeTransfer;
    const { user, loading } = userDetails;
    const [chartExchange, setChartExchange] = useState();
    useEffect(() => {
        dispatch(getUserDetails());
    }, [dispatch]);


   

    

    return (
        <div
            className={`wallet wallet--v1 ${
              checkIfOneIsTrue(variables)
                    ? "blur-modal"
                    : ""
            }`}
        >
           
            <div className="container container--dashboard">
                <div className="wallet-group">
                    <div className="wallet-group--right">
                        {/* Start::Advance Card */}
                        <div
                            className="advance-card advance-card--type-3-v3 aos-init aos-animate"
                            data-aos="fade-up"
                        >
                            <div className="advance-card-left">
                                <div className="advance-card__title">
                                    <h6 className="advance-card__title-text">
                                        Wallet Overview
                                    </h6>
                                </div>
                                <div className="advance-card__balance-wrapper">
                                    <div className="advance-card__balance">
                                        <p className="advance-card__balance-title">
                                            Total Balance
                                        </p>
                                        <p className="advance-card__balance-crypto-value">
                                            {!loading &&
                                            typeof loading !== "undefined"
                                                ? (
                                                      user.balance.income -
                                                      user.balance.expenses
                                                  ).toFixed(2)
                                                : 0}{" "}
                                            USD
                                        </p>
                                    </div>
                                    <div className="advance-card__balance-chart">
                                        <div className="advance-card__balance-chart-wrapper">
                                            <div
                                                id="advance-card__card-line-7"
                                                className="advance-card__balance-chart-render"
                                                style={{ minHeight: "80px" }}
                                            >
                                                {chartExchange ? (
                                                    <Chart
                                                        options={
                                                            chartExchange.options
                                                        }
                                                        series={
                                                            chartExchange.series
                                                        }
                                                        type="area"
                                                    />
                                                ) : null}
                                                
                                            </div>
                                        </div>
                                        <div className="advance-card__balance-chart-percent">
                                            <img
                                                src="/assets/media/images/icons/arrow-bearish.svg"
                                                alt=""
                                                className="advance-card__balance-chart-percent-image"
                                            />
                                            <p className="advance-card__balance-chart-percent-value text-bearish">
                                                2.36%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="advance-card__buttons">
                                    <button
                                        className="btn btn-primary advance-card__button btn-sm btn-pill"
                                        onClick={() =>
                                            setWithdrawModalOpened(true)
                                        }
                                    >
                                        Withdraw
                                    </button>
                                    <button
                                        className="btn btn-secondary advance-card__button btn-sm"
                                        onClick={() =>
                                            setDepositModalOpened(true)
                                        }
                                    >
                                        Deposit
                                    </button>
                                    <button
                                        className="btn btn-secondary advance-card__button btn-sm button-transfer_mobile"
                                        onClick={() =>
                                        setTransferOpened(true)
                                        }
                                    >
                                        Transfer
                                    </button>
                                </div>
                               
                            </div>
                            <div className="advance-card-separator" />
                            <BalanceDetails
                                userDetails={userDetails}
                                setChartExchangeWallet={setChartExchange}
                                toastId={toastId}
                               
                            />
                        </div>
                        {/* End::Advance Card */} {/* Start::Table Section */}
                       <WalletActions/>
                        {/* End::Table Section */}{" "}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Wallet;
