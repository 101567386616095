import { observer } from "mobx-react-lite";
import React from "react";
import mobxStore from "../mobx/mobxStore";
import OutsideAlerter from "../helpers/outsideAlerter";
import { useDispatch, useSelector } from "react-redux";
import { makeRefTransfer } from "../Redux/Actions/transactionsActions";
import { useEffect } from "react";
import { REF_TRANSFER_RESET } from "../Redux/Constans/transactionsConstans";
const TransferModal = observer((props) => {
    const { toastId, toast } = props;

    const { variables, setTransferOpened } = mobxStore;
    const dispatch = useDispatch();

    const makeRefTransferHandler = () => {
        dispatch(makeRefTransfer());
        setTransferOpened(false);
    };

    const makeTransfer = useSelector((state) => state.makeTransfer);
    const {
        loading: transferLoading,
        success: transferSuccess,
        error: transferError,
    } = makeTransfer;


    useEffect(()=>{

        if(!transferLoading && transferSuccess){
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Successfully transferred from referral balance to main balance",
                   
                );
            }
            dispatch({type:REF_TRANSFER_RESET});
        }

       
    },[dispatch,makeTransfer]);

    // if not open return nothing
    if (!variables.transferConfirmModalOpened) {
        return <></>;
    }

    return (
        <div className="modal-window">
            <OutsideAlerter
                data-aos="fade-up"
                className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
                onOutsideClick={() => setTransferOpened(false)}
            >
                <div className="advance-card__title">
                    <h6 className="advance-card__title-text">
                        Transfer Confirm
                    </h6>
                </div>
                <p
                    className="advance-card__forms-currency__title"
                    style={{ color: "#ffffff" }}
                >
                    Are you sure you want to transfer funds from your referral
                    balance to your main balance?
                </p>

                <button
                    className="btn btn-danger advance-card__button btn-sm btn-pill"
                    style={{ width: "50%", margin: "1.5rem auto " }}
                    onClick={() => setTransferOpened(false)}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary advance-card__button btn-sm btn-pill"
                    style={{}}
                    onClick={() => makeRefTransferHandler()}
                >
                    Confirm
                </button>
            </OutsideAlerter>
        </div>
    );
});

export default TransferModal;
