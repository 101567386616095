import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";




const PrivateRoute = ({ children }) => {
    const {userInfo}=useSelector(state=>state.userLogin);
    const {token}=userInfo;
    
  
    // let token = window.localStorage.getItem("userInfo");
    // token=JSON.parse(window.localStorage.getItem("userInfo"));
    return token ? children : <Navigate to="/sign-in" replace />;
   // return children;
};

export default PrivateRoute;
