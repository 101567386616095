import React from "react";
import OutsideAlerter from "../../helpers/outsideAlerter";

const WithdrawRequested = (props) => {
    const {
        loadingTariff,
        wallet,
        amount,
        onOutsideClickHandler,
        getReceiveAmount,
        tariff,
    } = props;
    return (
        <div className="modal-window">
            <OutsideAlerter
                className={`advance-card advance-card--type-1-v1 aos-init aos-animate `}
                onOutsideClick={() => {
                    onOutsideClickHandler();
                }}
            >
                <div className="advance-card__title">
                    <h6 className="advance-card__title-text">
                        Заявка успешно создана
                    </h6>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        Вывод каждую неделю в 12:00 по UTC
                    </p>
                </div>

                <div className="advance-card__forms-currency">
                    <div className="advance-card__forms-currency--pay">
                        <p className="advance-card__forms-currency__title">
                            Вы получите
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="number"
                                    disabled
                                    value={amount}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-2"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/usd.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="advance-card__forms-currency--get">
                        <p className="advance-card__forms-currency__title">
                            На кошелёк
                        </p>
                        <div className="forms-currency forms-currency--advance-card">
                            <div className="forms-group forms-group--value">
                                <input
                                    type="text"
                                    disabled
                                    value={wallet}
                                    className="form-control forms-currency__value"
                                />
                            </div>

                            <div
                                className="forms-group forms-group--currency js-forms-group--currency"
                                id="custom-select-currency-1"
                            >
                                <div
                                    className="forms-group__items selected"
                                    data-selected="usd"
                                >
                                    <img
                                        className="fg-items__icon"
                                        src="/assets/media/images/icons/logo-usdt.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advance-card__title">
                    <p className="advance-card__form-title-text">
                        С вашего баланса списано: {amount} USDT
                    </p>
                </div>
                <div className="advance-card__title">
                    <p>
                        Вы получите:{" "}
                        {!loadingTariff && tariff
                            ? getReceiveAmount(amount, tariff)
                            : "..."}{" "}
                        USDT
                    </p>
                </div>
                <button
                    className="advance-card__button btn btn-primary btn-pill"
                    onClick={() => onOutsideClickHandler()}
                >
                    Закрыть
                </button>
            </OutsideAlerter>
        </div>
    );
};

export default WithdrawRequested;
