import React, { useEffect, useState } from "react";
import "../css/pages/sign-in.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toast from "./Toast/toast";
import { Toastobjects, serverLink } from "../App";
import {
    getGrowthUser,
    getReferralStatus,
    getUserBalanceStats,
    getUserDetails,
    getUserDonutStats,
    getUserNotifications,
    getUserTariff,
    getUserTransactions,
    login,
} from "../Redux/Actions/userActions";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { get } from "mobx";
import {
    getCurrencies,
    getTransaction,
    getWallets,
} from "../Redux/Actions/transactionsActions";
import { fetchFromServer } from "../helpers/optimizationFetch";
const SignIn = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        document.documentElement.classList.add("sign-in-page");
    }, []);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberPassword, setRememberPassword] = useState(false);
    const [navigateNow, setNavigateNow] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const toastId = React.useRef(null);
    const userLogin = useSelector((state) => state.userLogin);
    const { error, userInfo, loading } = userLogin;
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };
    const getPrefetchData = async (token) => {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
       
        await Promise.all([
            fetchFromServer(
                `${serverLink}/api/user/profile`,
                "getUserDetails",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/transactions`,
                "getUserTransactions",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/stats`,
                "getUserDonutStats",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/growth?period=week`,
                `getGrowthUser?period=week`,
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/stats-balance`,
                "getUserBalanceStats",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/referral-status`,
                "getReferralStatus",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/tariff`,
                "getUserTariff",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/user/notifications`,
                "getUserNotifications",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/transaction/wallets`,
                "getWallets",
                config
            ),
            fetchFromServer(
                `${serverLink}/api/transaction/currencies`,
                "getCurrencies",
                config
            ),
        ]);

      
    };
    useEffect(() => {
   
        if (userInfo && typeof userInfo !== "undefined") {
         
            getPrefetchData(userInfo.token);
            //navigate("/dashboard");
            window.location.href="/dashboard";
        }
        if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(error, Toastobjects);
            }
        }
    }, [userInfo]);

    return (
        <div className="sign-in">
            <Toast />
            <div className="sign-in-card">
                <div className="sign-in__heading">
                    <img
                        src="/assets/media/images/icons/logo-signin.svg"
                        alt=""
                        className="logo aos-init aos-animate"
                        data-aos="fade-up"
                    />
                    <p
                        className="fb-sm sign-in__title aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={50}
                    >
                        Welcome back! Login to Cryptolly
                    </p>
                </div>

                <form onSubmit={submitHandler} className="sign-in__forms">
                    <div
                        className="forms-group aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={100}
                    >
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter your email address"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div
                        className="forms-group aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={150}
                    >
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter your password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            className="form-group__password-toggle js-form-group__password-toggle"
                            data-password="password"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>

                    <div
                        className="remember-forgot-password aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="rememberPassword"
                                name="rememberPassword"
                                checked={rememberPassword}
                                onChange={(e) =>
                                    setRememberPassword(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label fb-sm"
                                htmlFor="rememberPassword"
                            >
                                Remember password
                            </label>
                        </div>
                        <Link
                            to="/forgot-password"
                            className="forgot-password fb-sm"
                        >
                            Forgot password
                        </Link>
                    </div>

                    <button
                        type="submit"
                        className="btn btn-primary btn-pill btn-submit aos-init aos-animate"
                        disabled={userInfo || loading}
                        data-aos="zoom-in-up"
                        data-aos-delay={200}
                    >
                        {userInfo || loading ? (
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : null}
                        Sign In Now
                    </button>
                    <p
                        className="fb-sm sign-up-info aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={250}
                    >
                        Haven’t an account?{" "}
                        <Link to="/sign-up" className="sign-up-link">
                            Sign up for free
                        </Link>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
