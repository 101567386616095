import React from "react";
import Toast from "../Toast/toast";
import OutsideAlerter from "../../helpers/outsideAlerter";
import { uniqid } from "../../helpers/uniqid";
import { getIconFromServer } from "../../helpers/getImageFromServer";

const DepositDefault = (props) => {
    const {
        loadingTransaction,
        loadingCreate,
        loadingConfirm,
        loading,
        forceWalletsUpdate,
        setDepositModalOpened,
        wallets,
        currencySelected,
        receiveAmount,
        payAmount,
        payAmountChange,receiveAmountChange,
        setCurrencySelectOpened,
        createDepositHandler,
        currencySelectOpened,
        setCurrencySelectedHandler,
        closeModalHandler
    } = props;

    console.log(wallets);

    return (
        <React.Fragment>
            <Toast />

            <div className="modal-window">
                <OutsideAlerter
                    className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
                    onOutsideClick={() => closeModalHandler()}
                >
                        
                    <div className="advance-card__title">
                        <h6 className="advance-card__title-text">Deposit</h6>
                        <img
                            src="/assets/media/images/icons/exchange-white.svg"
                            alt=""
                            className="advance-card__title-icon"
                            onClick={forceWalletsUpdate}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                    <div
                        className={`advance-card__price ${
                            loading || loadingTransaction || loadingConfirm
                                ? "blur-loading2x"
                                : ""
                        }`}
                    >
                        <div className="advance-card__price-crypto">
                            <p className="advance-card__price-crypto-value">
                                1
                            </p>
                            <p className="advance-card__price-crypto-name">
                                {!loading && typeof loading !== "undefined"
                                    ? wallets.find(
                                          (wallet) =>
                                              wallet.name === currencySelected
                                      ).symbol
                                    : ""}
                            </p>
                        </div>
                        <img
                            src="../assets/media/images/icons/arrow-right-white.svg"
                            alt=""
                            className="advance-card__price-arrow"
                        />
                        <div className="advance-card__price-currency">
                            <p className="advance-card__price-currency-value">
                                {!loading && typeof loading !== "undefined"
                                    ? wallets.find(
                                          (wallet) =>
                                              wallet.name === currencySelected
                                      ).rate
                                    : ""}
                            </p>
                            <p className="advance-card__price-currency-name">
                                USD
                            </p>
                        </div>
                    </div>
                    <div
                        className={`advance-card__forms-currency ${
                            loading || loadingTransaction || loadingConfirm
                                ? "blur-loading2x"
                                : ""
                        }`}
                        style={
                            loadingCreate
                                ? {
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                  }
                                : {}
                        }
                    >
                        <div
                            className={`advance-card__forms-currency--get ${
                                loadingCreate ? "blur-loading2x" : ""
                            }`}
                        >
                            <p className="advance-card__forms-currency__title">
                                Get
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="number"
                                        min={0}
                                        value={receiveAmount}
                                        onChange={(e) => receiveAmountChange(e)}
                                        className="form-control forms-currency__value"
                                    />
                                </div>
                                <div className="forms-currency__line" />
                                <div
                                    className="forms-group forms-group--currency js-forms-group--currency"
                                    style={{ cursor: "unset" }}
                                    id="custom-select-currency-1"
                                >
                                    <div
                                        className="forms-group__items selected"
                                        data-selected="usd"
                                    >
                                        <img
                                            className="fg-items__icon"
                                            src="../assets/media/images/icons/usd.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`advance-card__forms-currency--pay ${
                                loadingCreate ? "blur-loading2x" : ""
                            }`}
                        >
                            <p className="advance-card__forms-currency__title">
                                Pay
                            </p>
                            <div className="forms-currency forms-currency--advance-card">
                                <div className="forms-group forms-group--value">
                                    <input
                                        type="number"
                                        min={0}
                                        value={payAmount}
                                        onChange={(e) => payAmountChange(e)}
                                        className="form-control forms-currency__value"
                                    />
                                </div>
                                <div className="forms-currency__line" />
                                <div
                                    className="forms-group forms-group--currency js-forms-group--currency"
                                    id="custom-select-currency-2"
                                    onClick={() =>
                                        setCurrencySelectOpened(true)
                                    }
                                >
                                    <div
                                        className="forms-group__items selected"
                                        data-selected="usd"
                                    >
                                        <img
                                            className="fg-items__icon"
                                            src={
                                                !loading &&
                                                typeof loading !== "undefined"
                                                    ? getIconFromServer(
                                                          wallets.find(
                                                              (wallet) =>
                                                                  wallet.name ===
                                                                  currencySelected
                                                          ).icon
                                                      )
                                                    : getIconFromServer(
                                                          "logo-usdt.svg"
                                                      )
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <img
                                        className="forms-currency__icon-arrow-down"
                                        src="../assets/media/images/icons/arrow-down.svg"
                                        alt=""
                                    />
                                    <OutsideAlerter
                                        className={`forms-group__dropdown js-forms-group__dropdown ${
                                            currencySelectOpened
                                                ? "is-open"
                                                : ""
                                        }`}
                                        onOutsideClick={() =>
                                            setCurrencySelectOpened(false)
                                        }
                                    >
                                        {!loading &&
                                        typeof loading !== "undefined"
                                            ? wallets.map((wallet) => (
                                                  <div
                                                      className="forms-group__items"
                                                      data-selected={
                                                          wallet.symbol
                                                      }
                                                      onClick={() =>
                                                          setCurrencySelectedHandler(
                                                              wallet.name
                                                          )
                                                      }
                                                      key={uniqid()}
                                                  >
                                                      <img
                                                          className="fg-items__icon"
                                                          src={getIconFromServer(
                                                              wallet.icon
                                                          )}
                                                          alt=""
                                                      />
                                                  </div>
                                              ))
                                            : null}
                                    </OutsideAlerter>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        {loadingCreate ? (
                            <img
                                src="https://i.gifer.com/YlWC.gif"
                                style={{ width: "50%", position: "absolute" }}
                            />
                        ) : null}
                    </div>
                    <button
                        className={`advance-card__button btn btn-primary btn-pill ${
                            loading || loadingCreate ? "blur-loading2x" : ""
                        }`}
                        disabled={loading || loadingCreate}
                        onClick={createDepositHandler}
                    >
                        Deposit
                    </button>
                </OutsideAlerter>
            </div>
        </React.Fragment>
    );
};

export default DepositDefault;
