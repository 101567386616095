import React from "react";

const HowWorks = () => {
    return (
        <section className="why-choose-us">
            <div className="why-choose-us__background why-choose-us__background--gradient">
                <img
                    className="why-choose-us__gradient-bg"
                    src="../assets/media/images/landing-page-why-choose-us-gradient.svg"
                    alt="Background Gradient Feature"
                />
            </div>
            <div className="why-choose-us__background why-choose-us__background--pattern">
                <img
                    className="why-choose-us__pattern-bg"
                    src="../assets/media/images/landing-page-why-choose-us-pattern.png"
                    alt="Background Pattern Feature"
                />
            </div>
            <div className="why-choose-us__background--mobile why-choose-us__background--gradient">
                <img
                    className="why-choose-us__gradient-bg"
                    src="../assets/media/images/mobile-responsive/mobile-landing-page-why-choose-us-gradient.png"
                    alt="Background Gradient Why Choose Us"
                />
            </div>
            <div className="why-choose-us__background--mobile why-choose-us__background--pattern">
                <img
                    className="why-choose-us__pattern-bg"
                    src="../assets/media/images/mobile-responsive/mobile-landing-page-why-choose-us-pattern.png"
                    alt="Background Pattern Why Choose Us"
                />
            </div>
            <div className="why-choose-us__background--mobile why-choose-us__background--pattern-2">
                <img
                    className="why-choose-us__pattern-bg"
                    src="../assets/media/images/mobile-responsive/mobile-landing-page-why-choose-us-pattern-2.png"
                    alt="Background Pattern Why Choose Us"
                />
            </div>
            <div className="container">
                <h2
                    className="text-center why-choose-us__title aos-init aos-animate"
                    data-aos="fade-up"
                >
                    Преимущества нашего бота:
                </h2>
                <p
                    className="fb-regular text-center why-choose-us__subtitle aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay={200}
                >
                   Максимизируйте ваш заработок с нашим криптовалютным арбитражным ботом!
                </p>
                <div className="why-choose-us__groups">
                    <div
                        className="why-choose-us__items aos-init aos-animate"
                        data-aos="zoom-in-up"
                        data-aos-delay={300}
                    >
                        <div className="why-choose-us-items__header">
                            <img
                                className="why-choose-us-items__icon"
                                src="../assets/media/images/icons/eyes.svg"
                                alt="Clarity"
                            />
                        </div>
                        <div className="why-choose-us-items__content">
                            <h5 className="why-choose-us-item__title">
                            Автоматизация
                            </h5>
                            <p className="fb-regular why-choose-us-item__desc">
                            Наш бот полностью автоматизирует процесс торговли, анализируя рынок и проводя выгодные сделки в реальном времени.
                            </p>
                        </div>
                    </div>
                    <div
                        className="why-choose-us__items aos-init aos-animate"
                        data-aos="zoom-in-up"
                        data-aos-delay={400}
                    >
                        <div className="why-choose-us-items__header">
                            <img
                                className="why-choose-us-items__icon"
                                src="../assets/media/images/icons/shield-done.svg"
                                alt="Confidence"
                            />
                        </div>
                        <div className="why-choose-us-items__content">
                            <h5 className="why-choose-us-item__title">
                            Скорость
                            </h5>
                            <p className="fb-regular why-choose-us-item__desc">
                            Благодаря мгновенной реакции на изменения курсов криптовалют, наш бот обеспечивает максимальную эффективность и минимизацию времени ожидания.
                            </p>
                        </div>
                    </div>
                    <div
                        className="why-choose-us__items aos-init aos-animate"
                        data-aos="zoom-in-up"
                        data-aos-delay={500}
                    >
                        <div className="why-choose-us-items__header">
                            <img
                                className="why-choose-us-items__icon"
                                src="../assets/media/images/icons/community.svg"
                                alt="Community"
                            />
                        </div>
                        <div className="why-choose-us-items__content">
                            <h5 className="why-choose-us-item__title">
                            Надежность
                            </h5>
                            <p className="fb-regular why-choose-us-item__desc">
                            Наш бот разработан опытными специалистами и прошел тщательное тестирование, чтобы гарантировать стабильную и надежную работу.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowWorks;
