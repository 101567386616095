import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";
import React from "react";
import { useDispatch } from "react-redux";
import OutsideAlerter from "../../helpers/outsideAlerter";

const DeleteAccountModal = observer((props) => {
    const { toastId, toast } = props;
    const { variables, setDeleteAccountModalOpened } = mobxStore;
    const dispatch = useDispatch();

    // if not open just return nothing
    if (!variables.deleteAccountModalOpened) {
        return <></>;
    }

    return (
        <div className="modal-window" style={{zIndex:"2"}}>
            <OutsideAlerter
                data-aos="fade-up"
                className="advance-card advance-card--type-1-v1 aos-init aos-animate modal-main"
                onOutsideClick={() => setDeleteAccountModalOpened(false)}
            >
                <div className="advance-card__title">
                    <h6 className="advance-card__title-text">Delete Account</h6>
                </div>
                <p
                    className="advance-card__forms-currency__title"
                    style={{ color: "#ffffff" }}
                >
                    Are you sure you want to delete your account? This is the
                    last stage of deletion; after clicking the “delete” button,
                    your account will be permanently deleted. All funds on your
                    account will also be permanently deleted.
                </p>
                <button
                    className="btn  btn-danger advance-card__button btn-sm btn-pill"
                    style={{ width: "50%", margin: "1.5rem auto " }}
                >
                    Confirm
                </button>
                <button
                    className="btn btn-primary  advance-card__button btn-sm btn-pill"
                    
                    onClick={() => setDeleteAccountModalOpened(false)}
                >
                    Cancel
                </button>
              
            </OutsideAlerter>
        </div>
    );
});

export default DeleteAccountModal;
