import {
    GET_CURRENCIES_REQUEST,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES_RESET,
    GET_WALLETS_REQUEST,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAIL,
    GET_WALLETS_RESET,
    CREATE_DEPOSIT_REQUEST,
    CREATE_DEPOSIT_SUCCESS,
    CREATE_DEPOSIT_FAIL,
    CREATE_DEPOSIT_RESET,
    GET_TRANSACTION_REQUEST,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAIL,
    GET_TRANSACTION_RESET,
    REF_TRANSFER_REQUEST,
    REF_TRANSFER_SUCCESS,
    REF_TRANSFER_FAIL,
    REF_TRANSFER_RESET,
    CREATE_WITHDRAW_REQUEST,
    CREATE_WITHDRAW_SUCCESS,
    CREATE_WITHDRAW_FAIL,
    CREATE_WITHDRAW_RESET,
    CONFIRM_DEPOSIT_REQUEST,
    CONFIRM_DEPOSIT_SUCCESS,
    CONFIRM_DEPOSIT_FAIL,
    CONFIRM_DEPOSIT_RESET,
} from "../Constans/transactionsConstans";

export const getCurrenciesReducer = (state = { currencies: [] }, action) => {
    switch (action.type) {
        case GET_CURRENCIES_REQUEST:
            return { loading: true, currencies: [] };
        case GET_CURRENCIES_SUCCESS:
            return { loading: false, currencies: action.payload };
        case GET_CURRENCIES_FAIL:
            return { loading: false, error: action.payload };
        case GET_CURRENCIES_RESET:
            return { currencies: [] };
        default:
            return state;
    }
};

export const getWalletsReducer = (state = { wallets: [] }, action) => {
    switch (action.type) {
        case GET_WALLETS_REQUEST:
            return { loading: true, wallets: [] };
        case GET_WALLETS_SUCCESS:
            return { loading: false, wallets: action.payload };
        case GET_WALLETS_FAIL:
            return { loading: false, error: action.payload };
        case GET_WALLETS_RESET:
            return { wallets: [] };
        default:
            return state;
    }
};

export const createDepositReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_DEPOSIT_REQUEST:
            return { loading: true };
        case CREATE_DEPOSIT_SUCCESS:
            return { loading: false, success: true, deposit: action.payload };
        case CREATE_DEPOSIT_FAIL:
            return { loading: false, error: action.payload };
        case CREATE_DEPOSIT_RESET:
            return {};
        default:
            return state;
    }
};

export const getTransactionReducer = (state = { transaction: {} }, action) => {
    switch (action.type) {
        case GET_TRANSACTION_REQUEST:
            return { loading: true, transaction: {} };
        case GET_TRANSACTION_SUCCESS:
            return { loading: false, transaction: action.payload };
        case GET_TRANSACTION_FAIL:
            return { loading: false, error: action.payload };
        case GET_TRANSACTION_RESET:
            return { transaction: {} };
        default:
            return state;
    }
};

export const makeRefTransferReducer = (state = {}, action) => {
    switch (action.type) {
        case REF_TRANSFER_REQUEST:
            return { loading: true };
        case REF_TRANSFER_SUCCESS:
            return {
                loading: false,
                success: true,
                refTransfer: action.payload,
            };
        case REF_TRANSFER_FAIL:
            return { loading: false, error: action.payload };
        case REF_TRANSFER_RESET:
            return {};
        default:
            return state;
    }
};

export const createWithdrawReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_WITHDRAW_REQUEST:
            return { loading: true };
        case CREATE_WITHDRAW_SUCCESS:
            return { loading: false, success: true, withdraw: action.payload };
        case CREATE_WITHDRAW_FAIL:
            return { loading: false, error: action.payload };
        case CREATE_WITHDRAW_RESET:
            return {};
        default:
            return state;
    }
};

export const confirmDepositReducer = (state = {}, action) => {
    switch (action.type) {
        case CONFIRM_DEPOSIT_REQUEST:
            return { loading: true };
        case CONFIRM_DEPOSIT_SUCCESS:
            return { loading: false, success: true, deposit: action.payload };
        case CONFIRM_DEPOSIT_FAIL:
            return { loading: false, error: action.payload };
        case CONFIRM_DEPOSIT_RESET:
            return {};
        default:
            return state;
    }
};
