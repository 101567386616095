import React, { useState } from "react";
import "../calculator.css";
import { uniqid } from "../../../helpers/uniqid";
import { useSelector } from "react-redux";

const MIN = 0;
const MAX = 10000;

const Calculator = () => {
    const [depositAmount, setDepositAmount] = useState(0);
    const [investments, setInvestments] = useState([
        {
            text: "1 неделя",
            length: 7,
            choosen: true,
        },
        {
            text: "1 месяц",
            length: 30,
            choosen: false,
        },
        {
            text: "6 месяцев",
            length: 180,
            choosen: false,
        },
        {
            text: "1 год",
            length: 365,
            choosen: false,
        },
    ]);

    const tariffsList=useSelector(state=>state.tariffsList);
    const {loading,tariffs,error}=tariffsList;


    const setInvestemt = (index) => {
        const copyInvestments = JSON.parse(JSON.stringify(investments));

        copyInvestments.forEach((item)=>{
                item.choosen=false;
        });
        copyInvestments[index].choosen = true;
    
        setInvestments(copyInvestments);
    };

    return (
        <section className="calculator">
            <div className="calculator__container">
                <div className="calculator__wrapper">
                    <div className="calculator__title">
                    Калькулятор инвестиций
                    </div>
                    <div className="calculator__description">
                    Оцените свой потенциальный доход и управляйте инвестициями с уверенностью
                    </div>
                    <div className="calculator__box">
                        <div className="calculator__amount">
                            <div className="calculator__amount-title">
                                Сумма депозита
                            </div>
                            <span>
                                <input type="number" id="amount__number" value={depositAmount} onChange={(e)=>setDepositAmount(e.target.value)}/>$
                            </span>
                        </div>
                        <div className="calculator__range">
                            <input
                                className="amount__range"
                                type="range"
                                min={MIN}
                                max={MAX}
                                value={depositAmount}
                                onChange={(e)=>setDepositAmount(e.target.value)}
                                style={{ backgroundSize: `${depositAmount/MAX*100}% 100%` }}
                            />
                        </div>
                      
                        <div className="calculator__select" >
                            <div className="calculator__select-title">
                               Период инвестиций
                            </div>
                            <div className="calculator__select-buttons">
                                {investments.map((invest,index) => (
                                    <button
                                        className={`calculator__select-btn ${invest.choosen?"active":""}`}
                                        key={uniqid()}
                                        onClick={()=>setInvestemt(index)}
                                    >
                                        {invest.text}
                                    </button>
                                ))}

                                
                            </div>
                        </div>
                        <div className="calculator__profit">
                            <div className="calculator__profit-item">
                                <div className="calculator__profit-name">
                                    Ваша прибыль
                                </div>
                                <div className="calculator__profit-value profit">
                                    +272 $
                                </div>
                            </div>
                            <div className="calculator__profit-item">
                                <div className="calculator__profit-name">
                                    Общий
                                </div>
                                <div className="calculator__profit-value total">
                                    2989 $
                                </div>
                            </div>
                            <div className="calculator__profit-item">
                                <div className="calculator__profit-name">
                                   Процент
                                </div>
                                <div className="calculator__profit-value percentage">
                                    10%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Calculator;
