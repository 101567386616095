import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import mobxStore from "../mobx/mobxStore";
import { useDispatch, useSelector } from "react-redux";
import {
    confirmDeposit,
    createDeposit,
    getTransaction,
    getWallets,
} from "../Redux/Actions/transactionsActions";


import { Toastobjects } from "../App";
import { useSearchParams } from "react-router-dom";

import DepositConfirmed from "./deposit/confirmed";
import DepositPending from "./deposit/pending";
import DepositCreated from "./deposit/created";
import DepositDefault from "./deposit/default";
import { GET_TRANSACTION_RESET } from "../Redux/Constans/transactionsConstans";



const DepositModal = observer((props) => {
    const {toastId,toast}=props
    const [searchParams, setSearchParams] = useSearchParams();
    const depositId = searchParams.get("deposit_id");
  
    const dispatch = useDispatch();
    const { variables, setDepositModalOpened } = mobxStore;
   
    const [currencySelected, setCurrencySelected] = useState("Tether");
    const [currencySelectOpened, setCurrencySelectOpened] = useState(false);
    const [receiveAmount, setReceiveAmount] = useState(100);
    const [payAmount, setPayAmount] = useState(100);

    const walletsList = useSelector((state) => state.walletsList);
    const { loading, wallets } = walletsList;

    const orderDepositCreate = useSelector((state) => state.orderDepositCreate);

    const {
        loading: loadingCreate,
        success: successCreate,
        error: errorCreate,
        deposit,
    } = orderDepositCreate;
    const userTransaction = useSelector((state) => state.userTransaction);

    const {
        loading: loadingTransaction,
        transaction,
        error: errorGetTransaction,
    } = userTransaction;
  

    const userConfirmDeposit = useSelector((state) => state.userConfirmDeposit);
    const {
        success: successConfirm,
        deposit: depositConfirm,
        loading: loadingConfirm,
        error: errorConfirm,
    } = userConfirmDeposit;

    useEffect(() => {

        if(typeof depositId==="string"){
            dispatch(getTransaction(depositId));
            dispatch(getWallets());
            setDepositModalOpened(true);
        }

        // if (
        //     depositId &&
        //     typeof loadingTransaction === "undefined" &&
        //     !successCreate
        // ) {
        //     setDepositModalOpened(true);
           
        // } else {
        //     if (searchParams.has("deposit_id")) {
        //         console.log("delete deposit_id");
        //      
        //         if (!toast.isActive(toastId.current)) {
        //             toastId.current = toast.error(
        //                 "Transaction not Found!",
        //                 Toastobjects
        //             );
        //         }
        //     }

           
        // }
    }, [dispatch,depositId]);
   
    useEffect(()=>{
        dispatch(getWallets());
    },[dispatch]);
  
    const receiveAmountChange = (e) => {
        if (!loading && typeof loading !== "undefined") {
            const currencyRate = wallets.find(
                (wallet) => wallet.name === currencySelected
            ).rate;

            setReceiveAmount(e.target.value);

            setPayAmount((e.target.value / currencyRate).toFixed(8));
        }
    };
    const payAmountChange = (e, currency) => {
        if (!loading && typeof loading !== "undefined") {
            const currCurrency = currency || currencySelected;
            const currencyRate = wallets.find(
                (wallet) => wallet.name === currCurrency
            ).rate;
            setPayAmount(e.target.value);
          
            setReceiveAmount((e.target.value * currencyRate).toFixed(2));
        }
    };

    const setCurrencySelectedHandler = (currency) => {
        setCurrencySelected(currency);
        setCurrencySelectOpened(false);
        payAmountChange({ target: { value: 1 } }, currency);
    };

    const forceWalletsUpdate = useCallback(() => {
        dispatch(getWallets());
    }, [dispatch]);
    const createDepositHandler = () => {
        dispatch(
            createDeposit({
                currency: currencySelected,
                amount: payAmount,
            })
        );
    };

    useEffect(() => {
        if (!loadingCreate && errorCreate) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(errorCreate, Toastobjects);
            }
       
        } else if (!loadingCreate && successCreate) {
            dispatch(getTransaction(orderDepositCreate.deposit._id));
            searchParams.set("deposit_id", orderDepositCreate.deposit._id);
            setSearchParams(searchParams);
        };
    }, [orderDepositCreate]);

    const confirmDepositHandler = (id) => {
        dispatch(confirmDeposit(id));
    };

    useEffect(() => {
        if (!loadingConfirm && successConfirm) {
            dispatch(getTransaction(depositConfirm._id));
        }
    }, [userConfirmDeposit]);


const closeModalHandler=()=>{
     searchParams.delete("deposit_id");
     setSearchParams(searchParams);
     setDepositModalOpened(false);
    dispatch({type:GET_TRANSACTION_RESET});



}

    // if not opened return nothing
    if (!variables.depositModalOpened) {
        return <></>;
    }

    // receipt confirmed
    if (
        !loadingTransaction &&
        typeof loadingTransaction !== "undefined" &&
        transaction &&
        transaction.status == 2
    ) {
        return (
      <DepositConfirmed setDepositModalOpened={setDepositModalOpened} loading={loading} loadingTransaction={loadingTransaction} loadingConfirm={loadingTransaction} closeModalHandler={closeModalHandler}/>
        );
    }

    // if deposit is pending. waiting for admin confirmation
    if (
        !loadingTransaction &&
        typeof loadingTransaction !== "undefined" &&
        transaction &&
        transaction.status == 1
    ) {
        return (
         <DepositPending loading={loading} loadingConfirm={loadingConfirm} loadingTransaction={loadingTransaction}
         transaction={transaction} setDepositModalOpened={setDepositModalOpened}
         closeModalHandler={closeModalHandler}
         />
        );
    }
    //if deposit is created or from uri return deposit window
    if (
        !loadingTransaction &&
        transaction &&
        !errorGetTransaction &&
        typeof loadingTransaction !== "undefined"
    ) {
        return (
          <DepositCreated loading={loading} loadingConfirm={loadingConfirm} transaction={transaction} loadingCreate={loadingCreate} confirmDepositHandler={confirmDepositHandler} setDepositModalOpened={setDepositModalOpened} closeModalHandler={closeModalHandler}/>
        );
    }

    console.log(walletsList);

    //default
    
    return (
        <DepositDefault setDepositModalOpened={setDepositModalOpened} forceWalletsUpdate={forceWalletsUpdate} loading={loading} loadingConfirm={loadingConfirm} loadingCreate={loadingCreate} loadingTransaction={loadingTransaction}
        wallets={wallets}
        currencySelected={currencySelected}
        receiveAmount={receiveAmount}
        payAmount={payAmount}

        payAmountChange={payAmountChange}
        receiveAmountChange={receiveAmountChange}
        setCurrencySelectOpened={setCurrencySelectOpened}
        createDepositHandler={createDepositHandler}
        currencySelectOpened={currencySelectOpened}
        setCurrencySelectedHandler={setCurrencySelectedHandler}
        closeModalHandler={closeModalHandler}
        />
    );
});

export default DepositModal;
