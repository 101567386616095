import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getIconFromServer } from "../../../helpers/getImageFromServer";
import { useDispatch, useSelector } from "react-redux";
import {
    getCurrenciesList,
    getGrowthUser,
    getUserDonutStats,
} from "../../../Redux/Actions/userActions";

const cardChartArr = [
    [
        "Desktops",
        [
            5, 0, 6, 0, 10, 2, 1, 6, 10, 6, 12, 17, 17, 22, 17, 19, 18, 22, 18,
            19, 17, 13, 15, 14, 14, 15, 13, 13, 10, 12,
        ],
        "#11CABE",
        [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
        ],
        "Bitcoin",
        "BTC",
        "logo-btc.svg",
        "53,850.80",
        "2.11%",
    ],
];

const GridGraph = () => {
    const [cardChartStats, setCardChartStats] = useState([]);
    const dispatch = useDispatch();

    // const getCurrenciesUnsecure = useSelector(
    //     (state) => state.getCurrenciesUnsecure
    // );

    // const { loading, currencies, error } = getCurrenciesUnsecure;

    // useEffect(() => {
    //     if (!loading && typeof loading !== "undefined" && currencies) {
    //         const chartArr = [];
    //         for (let i = 0; i < currencies.length; i++) {
    //             // cardChartArr.push(cardChartArr[0]);
    //             const coinsPrice = [];
    //             currencies[i].historical.forEach((history) => {
    //                 coinsPrice.push(parseFloat(history[3]));
    //             });
    //             chartArr.push([
    //                 "Desktops",
    //                 coinsPrice,
    //                 "#11CABE",
    //                 [
    //                     "1",
    //                     "2",
    //                     "3",
    //                     "4",
    //                     "5",
    //                     "6",
    //                     "7",
    //                     "8",
    //                     "9",
    //                     "10",
    //                     "11",
    //                     "12",
    //                     "13",
    //                     "14",
    //                     "15",
    //                     "16",
    //                     "17",
    //                     "18",
    //                     "19",
    //                     "20",
    //                     "21",
    //                     "22",
    //                     "23",
    //                     "24",
    //                     "25",
    //                     "26",
    //                     "27",
    //                     "28",
    //                     "29",
    //                     "30",
    //                 ],
    //                 currencies[i].name,
    //                 currencies[i].symbol,
    //                 currencies[i].icon,
    //                 currencies[i].marketData.lastPrice,
    //                 `${currencies[i].marketData.priceChangePercent}%`,
    //             ]);
    //         }

    //         cardChartArr.forEach((card) => {
    //             cardChartStats.push(
    //                 cardChart(
    //                     card[0],
    //                     card[1],
    //                     card[2],
    //                     card[3],
    //                     card[4],
    //                     card[5],
    //                     card[6],
    //                     card[7],
    //                     card[8]
    //                 )
    //             );

    //             setCardChartStats([...cardChartStats]);
    //         });
    //     }
    // }, [getCurrenciesUnsecure]);
    // useEffect(() => {
    //     dispatch(getCurrenciesList());
    // }, [dispatch]);

    const userGrowth = useSelector((state) => state.userGrowth);
    const { loading: loadingGrowth, error: errorGrowth, growth } = userGrowth;
    const userDonutStats = useSelector((state) => state.userDonutStats);

    const { loading: loadingStats, error: errorStats, stats } = userDonutStats;

    useEffect(() => {
        dispatch(getUserDonutStats());
        dispatch(getGrowthUser({ period: "month" }));
    }, [dispatch]);

    useEffect(() => {
        if (
            !loadingGrowth &&
            !loadingStats &&
            typeof loadingGrowth !== "undefined" &&
            typeof loadingStats !== "undefined"
        ) {
        }
    }, [userGrowth, userDonutStats]);

    console.log(userDonutStats);
    console.log(userGrowth);

    return (
        <div className="grid-graph">
            <div className="container">
                <div
                    className="grid-graph__group aos-init aos-animate"
                    data-aos="zoom-in-up"
                    data-aos-delay={100}
                >
                    {cardChartStats.map((chart, index) => (
                        <div
                            className={`grid-graph__item ${
                                index <= 2
                                    ? "grid-graph__item--mobile-hide"
                                    : ""
                            }`}
                        >
                            <div className="grid-graph-item__content">
                                <div className="grid-graph-item">
                                    <img
                                        className="grid-graph-item__crypto-icon"
                                        src={getIconFromServer(chart.coinLogo)}
                                        alt={chart.coinSymbol}
                                    />
                                </div>
                                <div className="grid-graph-item grid-graph-item__detail">
                                    <p className="fb-regular grid-graph-item__name">
                                        {chart.coinName}
                                    </p>
                                    <p className="fd-md fd-md--bold grid-graph-item__price">
                                        USD {chart.coinPrice}
                                    </p>
                                </div>
                                <div className="grid-graph-item">
                                    <p className="fb-regular grid-graph-item__code">
                                        {chart.coinSymbol}
                                    </p>
                                </div>
                            </div>
                            <div className="grid-graph-item__graph">
                                <div className="grid-grap-item-graph__img">
                                    <div
                                        className="grid-grap-item-graph__graph-render"
                                        style={{ minHeight: "115px" }}
                                    >
                                        <Chart
                                            options={chart.options}
                                            series={chart.series}
                                            type="area"
                                            height={"100px"}
                                        />
                                    </div>
                                </div>
                                <div className="grid-grap-item-graph__inflution grid-grap-item-graph__inflution--bullish">
                                    <img
                                        src="../assets/media/images/icons/arrow-bullish.svg"
                                        alt="Up"
                                    />
                                    <p className="fd-lg--bold">
                                        {chart.coinPercentChange}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="grid-graph__item grid-graph__item--last">
                        <a
                            href="#"
                            className="btn btn-secondary btn-sm grid-graph-item__button"
                        >
                            View All Assets
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GridGraph;

function cardChart(
    name,
    data,
    color,
    categories,
    coinName,
    coinSymbol,
    coinLogo,
    coinPrice,
    coinPercentChange
) {
    // check if this page have this class
    const series = [
        {
            name: name,
            data: data,
        },
    ];

    var options = {
        chart: {
            height: 100,
            type: "area",
            redrawOnWindowResize: true,
            redrawOnParentResize: true,
            toolbar: { show: false },
            zoom: {
                enabled: false,
            },
            stacked: false,
        },
        dataLabels: {
            enabled: false,
        },
        colors: [color],
        stroke: {
            width: 1,
            curve: "straight",
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0,
                stops: [0, 80, 100],
            },
        },
        grid: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        xaxis: {
            categories: categories,
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: { show: false },
        },
        yaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: { show: false },
        },
    };
    return {
        options,
        series,
        coinLogo,
        coinName,
        coinSymbol,
        coinPrice,
        coinPercentChange,
    };
}
