import React from "react";
import "../../css/pages/dashboard.css";
import CoinsPrice from "../dashboard/coinsPrice";

import Transactions from "./transactions";
import Tariffs from "./tariffs";
const Exchange = () => {
    return (
        <div className={`dashboard dashboard--v1 wallet wallet-v2`} >
        <div className="container container--dashboard">
            <div className="dashboard__group">
                    <CoinsPrice />
                   <Transactions/>
                </div>
            </div>
        </div>
    );
};

export default Exchange;
