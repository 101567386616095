import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getUserDonutStats, getUserDonutStats7D } from "../../Redux/Actions/userActions";
import { getIconFromServer } from "../../helpers/getImageFromServer";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";
import { uniqid } from "../../helpers/uniqid";
const DonutChart = (props) => {
    const { setIncome24H } = props;
    const dispatch = useDispatch();
    const [donutChart, setDonutChart] = useState();
    const {setDepositModalOpened}=mobxStore;

    const userDonutStats7D = useSelector((state) => state.userDonutStats7D);

    const { loading, error, stats } = userDonutStats7D;

    useEffect(() => {
        dispatch(getUserDonutStats7D());
    }, [dispatch]);
   


   
    useEffect(() => {
        if (!loading && typeof loading !== "undefined") {
            setIncome24H(stats.income24h);
            const incomes = stats.incomeCurrencies.map(
                (item) => item.income24h
            );

            const donutChartInfo = getWalletDonutChart(incomes, [
                "#F7931A",
                "#30E0A1",
                "#BD47FB",
            ]);

            setDonutChart(donutChartInfo);
        }
    }, [userDonutStats7D]);
    console.log(stats);
    return (
        <div
            className={`complete-card__wallet  ${
                typeof loading === "undefined" || loading
                    ? "blur-loading2x"
                    : ""
            }`}
            style={{ position: "relative" }}
        >
            {typeof loading !== "undefined" && !loading && stats.noBalance ? (
                <button
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                    }}
                    className="btn btn-primary advance-card__button btn-sm btn-pill"
                    onClick={()=>setDepositModalOpened(true)}
                >
                    Deposit
                </button>
            ) : null}

            <div
                className={`complete-card__wallet-head  ${
                    typeof loading !== "undefined" &&
                    !loading &&stats&&
                    stats.noBalance
                        ? "blur-loading2x"
                        : ""
                }`}
            >
                <h6 className="complete-card__wallet-head__title-text">
                    Wallet
                </h6>
                <p className="fb-regular complete-card__wallet-head__title-total-currencies">
                {!loading && typeof loading !== "undefined"
                                ? stats.incomeCurrencies.length
                                : "2"} Currencies
                </p>
            </div>
            <div
                className={`complete-card__wallet-data   ${
                    typeof loading !== "undefined" &&
                    !loading && stats&&
                    stats.noBalance
                        ? "blur-loading2x"
                        : ""
                }`}
            >
                <div className="complete-card__wallet-data__chart">
                    <div
                        id="complete-card__wallet-data__donut-chart-1"
                        className="complete-card__wallet-data__donut-chart-render"
                        style={{ minHeight: "139.65px" }}
                    >
                        {donutChart ? (
                            <Chart
                                options={donutChart.options}
                                series={donutChart.series}
                                type="donut"
                            />
                        ) : null}
                    </div>
                    <div className="complete-card__wallet-data__donut-chart-label" style={!loading&&typeof loading!=="undefined"?{}:{display:"none"}}>
                        <img
                            src="/assets/media/images/icons/arrow-bullish.svg"
                            alt=""
                            className="complete-card__wallet-data__donut-chart-label-icon"
                        />
                        <p className="fd-sm fd-sm--bold complete-card__wallet-data__donut-chart-label-text">
                            {!loading && typeof loading !== "undefined"
                                ? (stats.income24h*100).toFixed(2)
                                : "0.00"}
                            %
                        </p>
                    </div>
                </div>
                <div className="complete-card__wallet-data__currencies">
                    {!loading && typeof loading !== "undefined" ? (
                        stats.incomeCurrencies.map((item) => (
                            <div className="wallet-data__currency" key={uniqid()}>
                                <img
                                    src={getIconFromServer(item.icon)}
                                    alt=""
                                    className="wallet-data__currency-icon"
                                />
                                <div className="wallet-data__currency-text">
                                    <p className="fb-regular fb-regular--bold wallet-data__currency-text-acronym">
                                        {item.symbol}
                                    </p>
                                    <p className="fb-regular wallet-data__currency-text-name">
                                        {item.name}
                                    </p>
                                </div>
                                <div className="wallet-data__currency-influsion">
                                    <img
                                        src="/assets/media/images/icons/arrow-bullish.svg"
                                        alt=""
                                        className="wallet-data__currency-influsion-icon"
                                    />
                                    <p className="fb-sm wallet-data__currency-influsion-value">
                                        {(item.income24h*100).toFixed(2)}%
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <React.Fragment>
                            <div className="wallet-data__currency">
                                <img
                                    src="/assets/media/images/icons/logo-eth.svg"
                                    alt=""
                                    className="wallet-data__currency-icon"
                                />
                                <div className="wallet-data__currency-text">
                                    <p className="fb-regular fb-regular--bold wallet-data__currency-text-acronym">
                                        ETH
                                    </p>
                                    <p className="fb-regular wallet-data__currency-text-name">
                                        Ethereum
                                    </p>
                                </div>
                                <div className="wallet-data__currency-influsion">
                                    <img
                                        src="/assets/media/images/icons/arrow-bullish.svg"
                                        alt=""
                                        className="wallet-data__currency-influsion-icon"
                                    />
                                    <p className="fb-sm wallet-data__currency-influsion-value">
                                        1.80%
                                    </p>
                                </div>
                            </div>
                            <div className="wallet-data__currency">
                                <img
                                    src="/assets/media/images/icons/logo-usdt.svg"
                                    alt=""
                                    className="wallet-data__currency-icon"
                                />
                                <div className="wallet-data__currency-text">
                                    <p className="fb-regular fb-regular--bold wallet-data__currency-text-acronym">
                                        USDT
                                    </p>
                                    <p className="fb-regular wallet-data__currency-text-name">
                                        Tether
                                    </p>
                                </div>
                                <div className="wallet-data__currency-influsion">
                                    <img
                                        src="/assets/media/images/icons/arrow-bullish.svg"
                                        alt=""
                                        className="wallet-data__currency-influsion-icon"
                                    />
                                    <p className="fb-sm wallet-data__currency-influsion-value">
                                        1.64%
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DonutChart;

function getWalletDonutChart(series, colors) {
    var options = {
        series: series,
        options: {
            colors: colors,
            chart: {
                type: "donut",
                width: "163px",
                height: "163px",
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
                stacked: false,
            },
            dataLabels: {
                enabled: false,
            },
            legend: { show: false },
            tooltip: {
                enabled: false,
            },
            stroke: {
                show: true,
                colors: "#1E1F25",
                width: 3,
            },
        },
    };
    return options;
}
