import React from "react";

const Feature=()=>{
        return (
                <section className="feature">
                <div className="feature__background feature__background--gradient">
                    <img
                        className="feature__gradient-bg"
                        src="/assets/media/images/landing-page-featured-gradient.svg"
                        alt="Background Gradient Feature"
                    />
                </div>
                <div className="feature__background feature__background--pattern">
                    <img
                        className="feature__pattern-bg"
                        src="/assets/media/images/landing-page-featured-pattern.svg"
                        alt="Background Pattern Feature"
                    />
                </div>
                <div className="feature__background--mobile feature__background--gradient">
                    <img
                        className="feature__gradient-bg"
                        src="/assets/media/images/mobile-responsive/mobile-landing-page-featured-gradient.png"
                        alt="Background Gradient Feature"
                    />
                </div>
                <div className="feature__background--mobile feature__background--pattern">
                    <img
                        className="feature__pattern-bg"
                        src="/assets/media/images/mobile-responsive/mobile-landing-page-featured-pattern.png"
                        alt="Background Pattern Feature"
                    />
                </div>
                <div className="container">
                    <h2
                        className="text-center feature__title aos-init aos-animate"
                        data-aos="fade-up"
                    >
                        Зарабатывайте пасивно и безопасно.
                    </h2>
                    <p
                        className="fb-regular text-center feature__subtitle aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay={200}
                    >
                        Бот - который действительно приносит пасивный заработок каждый день!
                    </p>
                    <div className="feature__group">
                        <div
                            className="feature__items aos-init aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-delay={300}
                        >
                            <div className="feature-items__img">
                                <img
                                    src="/assets/media/images/icons/icon-portofolio-manager.svg"
                                    alt="Portofolio Manager"
                                />
                            </div>
                            <h5 className="feature-items__title">
                            Автоматизация
                            </h5>
                            <p className="fb-regular feature-items__desc">
                            Наше программное обеспечение работает автономно, что позволяет вам сэкономить время и увеличить эффективность торговли.
                            </p>
                        </div>
                        <div
                            className="feature__items aos-init aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-delay={450}
                        >
                            <div className="feature-items__img">
                                <img
                                    src="/assets/media/images/icons/phone.svg"
                                    alt="Mobile Apps"
                                />
                            </div>
                            <h5 className="feature-items__title">
                            Безопасность
                            </h5>
                            <p className="fb-regular feature-items__desc">
                            Мы используем передовые технологии шифрования, чтобы обеспечить безопасность ваших средств и данных.
                            </p>
                        </div>
                        <div
                            className="feature__items aos-init aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-delay={300}
                        >
                            <div className="feature-items__img">
                                <img
                                    src="/assets/media/images/icons/lock.svg"
                                    alt="Vault protection"
                                />
                            </div>
                            <h5 className="feature-items__title">
                            Поддержка
                            </h5>
                            <p className="fb-regular feature-items__desc">
                            Наша команда профессионалов всегда готова помочь вам решить любые вопросы и проблемы.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
}

export default Feature;