import { getReferralStatus } from "../Actions/userActions";
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_TRANSACTIONS_REQUEST,
    USER_TRANSACTIONS_SUCCESS,
    USER_TRANSACTIONS_FAIL,
    USER_TRANSACTIONS_RESET,
    USER_DONUT_STATS_REQUEST,
    USER_DONUT_STATS_SUCCESS,
    USER_DONUT_STATS_FAIL,
    USER_DONUT_STATS_RESET,
    USER_GROWTH_REQUEST,
    USER_GROWTH_SUCCESS,
    USER_GROWTH_FAIL,
    USER_GROWTH_RESET,
    USER_BALANCE_STATS_FAIL,
    USER_BALANCE_STATS_REQUEST,
    USER_BALANCE_STATS_RESET,
    USER_BALANCE_STATS_SUCCESS,
    STATUS_REFERRAL_REQUEST,
    STATUS_REFERRAL_SUCCESS,
    STATUS_REFERRAL_FAIL,
    STATUS_REFERRAL_RESET,
    GET_TARIFF_REQUEST,
GET_TARIFF_SUCCESS,
GET_TARIFF_FAIL,
GET_TARIFF_RESET,
GET_NOTIFICATIONS_REQUEST,
GET_NOTIFICATIONS_SUCCESS,
GET_NOTIFICATIONS_FAIL,
GET_NOTIFICATIONS_RESET,
GET_TARIFFS_LIST_REQUEST,
GET_TARIFFS_LIST_SUCCESS,
GET_TARIFFS_LIST_FAIL,
GET_TARIFFS_LIST_RESET,
USER_WALLET_ACTIONS_REQUEST,
USER_WALLET_ACTIONS_SUCCESS,
USER_WALLET_ACTIONS_FAIL,
USER_WALLET_ACTIONS_RESET,
GET_CURRENCIES_REQUEST,
GET_CURRENCIES_SUCCESS,
GET_CURRENCIES_FAIL,
GET_CURRENCIES_RESET,
USER_DONUT_STATS7D_REQUEST,
USER_DONUT_STATS7D_SUCCESS,
USER_DONUT_STATS7D_FAIL,
USER_DONUT_STATS7D_RESET
} from "../Constans/userContains";

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
};

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true };
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state, loading: true };
        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload };
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case USER_DETAILS_RESET:
            return { user: {} };
        default:
            return state;
    }
};

export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true };
        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload };
        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userTransactionsReducer = (
    state = { transactions: [] },
    action
) => {
    switch (action.type) {
        case USER_TRANSACTIONS_REQUEST:
            return { ...state, loading: true };
        case USER_TRANSACTIONS_SUCCESS:
            return { loading: false, transactions: action.payload };
        case USER_TRANSACTIONS_FAIL:
            return { loading: false, error: action.payload };
        case USER_TRANSACTIONS_RESET:
            return { transactions: [] };
        default:
            return state;
    }
};

export const userDonutStatsReducer = (state = { stats: {} }, action) => {
    switch (action.type) {
        case USER_DONUT_STATS_REQUEST:
            return { loading: true };
        case USER_DONUT_STATS_SUCCESS:
            return { loading: false, stats: action.payload };
        case USER_DONUT_STATS_FAIL:
            return { loading: false, error: action.payload };
        case USER_DONUT_STATS_RESET:
            return { stats: [] };
        default:
            return state;
    }
};

export const userDonutStats7DReducer = (state = { stats: {} }, action) => {
    switch (action.type) {
        case USER_DONUT_STATS7D_REQUEST:
            return { loading: true };
        case USER_DONUT_STATS7D_SUCCESS:
            return { loading: false, stats: action.payload };
        case USER_DONUT_STATS7D_FAIL:
            return { loading: false, error: action.payload };
        case USER_DONUT_STATS7D_RESET:
            return { stats: [] };
        default:
            return state;
    }
};

export const userGrowthReducer = (state = { growth: [] }, action) => {
    switch (action.type) {
        case USER_GROWTH_REQUEST:
            return { loading: true };
        case USER_GROWTH_SUCCESS:
            return { loading: false, growth: action.payload };
        case USER_GROWTH_FAIL:
            return { loading: false, error: action.payload };
        case USER_GROWTH_RESET:
            return { growth: [] };
        default:
            return state;
    }
};

export const userBalanceStatsReducer = (state = { balance: {} }, action) => {
    switch (action.type) {
        case USER_BALANCE_STATS_REQUEST:
            return { loading: true };
        case USER_BALANCE_STATS_SUCCESS:
            return { loading: false, balance: action.payload };
        case USER_BALANCE_STATS_FAIL:
            return { loading: false, error: action.payload };
        case USER_BALANCE_STATS_RESET:
            return { balance: [] };
        default:
            return state;
    }
};

export const getReferralStatusReducer = (state = { referral: {} }, action) => {
    switch (action.type) {
        case STATUS_REFERRAL_REQUEST:
            return { loading: true };
        case STATUS_REFERRAL_SUCCESS:
            return { loading: false, referral: action.payload };
        case STATUS_REFERRAL_FAIL:
            return { loading: false, error: action.payload };
        case STATUS_REFERRAL_RESET:
            return { referral: [] };
        default:
            return state;
    }
};

export const getUserTariffReducer = (state = { tariff: {} }, action) => {
    switch (action.type) {
        case GET_TARIFF_REQUEST:
            return { loading: true };
        case GET_TARIFF_SUCCESS:
            return { loading: false, tariff: action.payload };
        case GET_TARIFF_FAIL:
            return { loading: false, error: action.payload };
        case GET_TARIFF_RESET:
            return { tariff: [] };
        default:
            return state;
    }
};


export const getNotificationsReducer = (state = { notifications: [] }, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST:
            return { loading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            return { loading: false, notifications: action.payload };
        case GET_NOTIFICATIONS_FAIL:
            return { loading: false, error: action.payload };
        case GET_NOTIFICATIONS_RESET:
            return { notifications: [] };
        default:
            return state;
    }
};

export const getTariffsReducer = (state = { tariffs: [] }, action) => {
    switch (action.type) {
        case GET_TARIFFS_LIST_REQUEST:
            return { loading: true };
        case GET_TARIFFS_LIST_SUCCESS:
            return { loading: false, tariffs: action.payload };
        case GET_TARIFFS_LIST_FAIL:
            return { loading: false, error: action.payload };
        case GET_TARIFFS_LIST_RESET:
            return { tariffs: [] };
        default:
            return state;
    }
};


export const getUserWalletActionsReducer = (state = { transactions: [] }, action) => {
    switch (action.type) {
        case USER_WALLET_ACTIONS_REQUEST:
            return { loading: true };
        case USER_WALLET_ACTIONS_SUCCESS:
            return { loading: false, transactions: action.payload };
        case USER_WALLET_ACTIONS_FAIL:
            return { loading: false, error: action.payload };
        case USER_WALLET_ACTIONS_RESET:
            return { transactions: [] };
        default:
            return state;
    }
};

export const getCurrenciesListReducer = (state = { currencies: [] }, action) => {
    switch (action.type) {
        case GET_CURRENCIES_REQUEST:
            return { loading           : true };
        case GET_CURRENCIES_SUCCESS:
            return { loading: false, currencies: action.payload };
        case GET_CURRENCIES_FAIL:
            return { loading: false, error: action.payload };
        case GET_CURRENCIES_RESET:
            return { currencies: [] };
        default:
            return state;
    }
};
